import React, { useState, useEffect } from "react";
import useStyles from "./style.js";
import { Modal, Fade } from "@material-ui/core";

import OutlinedInput from "../OutlinedInput";
import Checkbox from "../CheckBox";
import Button from "../Button";
import constants from "../../config/constants";
import SelectInput from "../SelectInput";

import circleCrossBlack from "../../assets/icons/circleCrossBlack.png";
const { EMAIL_REGEX } = constants;

const genderOptions = [
  {
    label: "Male",
    value: "m",
  },
  {
    label: "Female",
    value: "f",
  },
];

const AddUserModal = (props) => {
  const { open, selectedTab, onClose, onSave, editUserData, onUpdatePress } =
    props;
  const classes = useStyles();
  const [userData, setUserData] = useState({ gender: "m" });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (editUserData) {
      const payload = {
        firstName: editUserData.profile.firstName,
        lastName: editUserData.profile.lastName,
        email: editUserData.email,
        phone: editUserData.profile.phone,
      };

      if (editUserData.roles && editUserData.roles.length) {
        const roles = [];
        const adminRoleFound = editUserData.roles.findIndex(
          (item) => item.code === "admin"
        );

        if (adminRoleFound !== -1) {
          roles.push("admin");
        }

        const agentRoleFound = editUserData.roles.findIndex(
          (item) => item.code === "agent"
        );

        if (agentRoleFound !== -1) {
          roles.push("agent");
        }

        const userRoleFound = editUserData.roles.findIndex(
          (item) => item.code === "user"
        );

        if (userRoleFound !== -1) {
          roles.push("user");
        }

        payload.roles = roles;
      }

      setUserData(payload);
      return;
    }

    const data = { ...userData };

    if (data.roles) {
      data.roles.push(selectedTab);
    } else {
      data.roles = [selectedTab];
    }

    setUserData(data);
  }, []); // eslint-disable-line

  const onChecked = (value) => {
    const data = { ...userData };
    const role = data.roles || [];
    const indexFound = role.findIndex((item) => item === value);
    if (indexFound === -1) {
      role.push(value);
    } else {
      role.splice(indexFound, 1);
    }
    data.roles = role;
    setUserData(data);
  };

  const handleChange = (e, key) => {
    if (key === "phone" && isNaN(e.target.value)) return;
    if (key === "gender") {
      userData[key] = e;
    } else {
      userData[key] = e?.target?.value;
      errors[key] = "";
    }
    setErrors({ ...errors });
    setUserData({ ...userData });
  };

  const saveUser = () => {
    if (!isValidFields()) return;
    onSave(userData);
  };

  const editUser = () => {
    if (!isValidFields()) return;
    onUpdatePress(userData);
  };

  const isValidFields = () => {
    let isValid = true;
    let errors = {};

    if (!userData.firstName) {
      errors.firstName = "Required";
      isValid = false;
    }
    if (!userData.lastName) {
      errors.lastName = "Required";
      isValid = false;
    }
    if (!userData.email) {
      errors.email = "Required";
      isValid = false;
    }
    if (!userData.phone) {
      errors.phone = "Required";
      isValid = false;
    }
    if (userData.email && !EMAIL_REGEX.test(userData.email)) {
      errors.email = "Email is not valid";
      isValid = false;
    }
    setErrors(errors);
    return isValid;
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!!editUserData) {
      editUser();
    } else {
      saveUser();
    }
  };
  return (
    <Modal
      className={classes.modal}
      open={open}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade timeout={{ appear: 0, enter: 500 }} in={open}>
        <div className={classes.paper}>
          <div className={classes.closeIconBox} onClick={onClose}>
            <img
              alt="Close"
              src={circleCrossBlack}
              className={classes.closeIcon}
            />
          </div>
          <div className={classes.container}>
            <p className={classes.title}>
              {!!editUserData ? "Edit Details" : "Add Detail"}
            </p>
            <form onSubmit={onSubmit}>
              <div className={classes.row}>
                <OutlinedInput
                  label="First Name"
                  errorMessage={errors.firstName}
                  value={userData.firstName}
                  onChange={(e) => handleChange(e, "firstName")}
                  fullWidth
                  containerStyle={{ marginRight: "20px" }}
                />
                <OutlinedInput
                  label="Last Name"
                  errorMessage={errors.lastName}
                  value={userData.lastName}
                  onChange={(e) => handleChange(e, "lastName")}
                  fullWidth
                />
              </div>
              <div className={classes.row}>
                <OutlinedInput
                  label="Email"
                  errorMessage={errors.email}
                  value={userData.email}
                  onChange={(e) => handleChange(e, "email")}
                  fullWidth
                  containerStyle={{ marginRight: "20px" }}
                  disabled={!!editUserData}
                />
                <OutlinedInput
                  label="Phone"
                  errorMessage={errors.phone}
                  value={userData.phone}
                  onChange={(e) => handleChange(e, "phone")}
                  fullWidth
                />
              </div>
              <div className={classes.row}>
                <SelectInput
                  placeholder="Select gender"
                  options={genderOptions}
                  onChange={(e) => handleChange(e, "gender")}
                  value={userData.gender}
                />
              </div>
              <div className={classes.roleBox}>
                <p className={classes.title}>Select Role</p>
                <div className={classes.checkBoxSection}>
                  <div className="mr-1">
                    <Checkbox
                      label="Admin"
                      checked={
                        userData?.roles
                          ? userData?.roles.includes("admin")
                          : false
                      }
                      onChange={() => onChecked("admin")}
                    />
                  </div>
                  <div className="mr-1">
                    <Checkbox
                      label="Agent"
                      checked={
                        userData?.roles
                          ? userData?.roles.includes("agent")
                          : false
                      }
                      onChange={() => onChecked("agent")}
                    />
                  </div>
                  {userData?.roles && userData?.roles.includes("user") && (
                    <div>
                      <Checkbox disabled={true} label="User" checked={true} />
                    </div>
                  )}
                </div>
              </div>
              <Button
                type="submit"
                title={!!editUserData ? "Update" : "Save"}
                theme="primary"
                buttonStyle={{ width: "150px", marginTop: "40px" }}
              />
            </form>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default AddUserModal;
