import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    width: "100%",
    backgroundColor: theme.palette.background,
    height: "50px",
    display: "flex",
    flexDirection: "row",
    borderRadius: "10px",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontSize: "14px",
    cursor: "pointer",
  },
  label: {
    fontSize: "14px",
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
  addIcon: {
    width: "20px",
  },
  dialogBodyContainer: {
    background: theme.palette.white,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  dialogFooterContainer: {
    background: theme.palette.white,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
  crossIcon: {
    width: "25px",
  },
  countryListContainer: {
    maxHeight: "500px",
    height: "80vh",
    overflowY: "auto",
  },
}));

export default useStyles;
