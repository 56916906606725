import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    backgroundColor: theme.palette.background,
    borderRadius: 9,
  },
  input: {
    color: theme.palette.darkGrey,
    backgroundColor: theme.palette.background,
    "&.Mui-selected": {
      backgroundColor: theme.palette.grey,
      fontWeight: 600,
      color: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.lightGrey,
        color: theme.palette.darkGrey,
      },
    },
    "&:hover": {
      backgroundColor: theme.palette.lightGrey,
      color: theme.palette.darkGrey,
    },
  },
  select: {
    width: "100%",
    height: "50px",
    padding: "10px",
    "&:before": {
      borderColor: theme.palette.background,
    },
    "&:after": {
      borderColor: theme.palette.background,
    },
  },
}));

export default useStyles;
