import React from "react";
import Dialog from "@material-ui/core/Dialog";

import Button from "../Button";
import useStyles from "./style";

const ConfirmationPopup = (props) => {
  const classes = useStyles();

  //   const handleClose = () => {
  //     if (props.onClose) {
  //       props.onClose();
  //     }
  //   };

  const handleConfirm = () => {
    if (props.data.onConfirm) {
      props.data.onConfirm();
    }
  };

  const handleCancel = () => {
    if (props.data.onConfirm) {
      props.data.onCancel();
    }
  };

  return (
    <Dialog fullWidth={true} open={props.data.open}>
      <div className={classes.container}>
        <div className={classes.contentContainer}>
          <p className={classes.text}>{props.data.text}</p>
        </div>

        <div className={classes.buttonContainer}>
          <div className={classes.button}>
            <Button
              onClick={handleCancel}
              type="submit"
              title="Cancel"
              theme="default"
            />
          </div>
          <div className={`${classes.button} ${classes.secondButton}`}>
            <Button
              onClick={handleConfirm}
              theme="danger"
              type="submit"
              title="Continue"
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

ConfirmationPopup.defaultProps = {
  data: {},
};

export default ConfirmationPopup;
