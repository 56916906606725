import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  welcomeHeading: {
    fontWeight: "bold",
  },
  greetContainer: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  soladLogo: {
    width: 220,
  },
}));

export default useStyles;
