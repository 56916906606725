import useStyles from "./style";

const TextButton = (props) => {
  const classes = useStyles();

  return (
    <span onClick={props.onClick} style={props.style} className={classes.text}>
      {props.children}
    </span>
  );
};

TextButton.defaultProps = {
  style: {},
};

export default TextButton;
