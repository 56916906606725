import React from "react";
import useStyles from "./style.js";

import "./style.js";
import backgroundImageCircle from "../../assets/images/login/ellipse.png";
import backgroundImagePolygon from "../../assets/images/login/polygon.png";

const LoginBackground = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.backgroundImageCircleContainer}>
        <img
          alt=""
          className={classes.backgroundImageCircle}
          src={backgroundImageCircle}
        />
      </div>
      <div className={classes.backgroundImagePolygonContainer}>
        <img
          alt=""
          className={classes.backgroundImagePolygon}
          src={backgroundImagePolygon}
        />
      </div>
    </>
  );
};

export default LoginBackground;
