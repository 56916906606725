import React, { useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";

import ChangeFirstPasswordModal from "../../components/ChangeFirstPasswordModal/index.js";
import useStyles from "./style.js";
import LoginBackground from "../../components/LoginBackground/index.js";
import LoginContainerRightBackground from "../../assets/images/login/loginRightContainerBackground.png";
import SoladLogo from "../../assets/images/login/soladLogo.png";
import Loader from "../../components/Loader";
import Button from "../../components/Button";
import OutlinedInput from "../../components/OutlinedInput";
import MessageModal from "../../components/MessageModal";
import api from "../../utils/api";
import authActions from "../../redux/auth/action";

const { setUser } = authActions;

const Login = (props) => {
  const classes = useStyles();
  const { history } = props;
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
    email: "", 
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [firstPasswordModal, setFirstPasswordModal] = useState({
    open: false,
    data: {},
  });

  const dispatch = useDispatch();

  const handleChange = (prop) => (event) => {
    const previousValues = { ...values };

    if (prop === "email") {
      previousValues.password = "";
    }

    setValues({ ...previousValues, [prop]: event.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    login();
  };

  const validate = () => {
    const errors = {};

    if (!values.email) {
      errors.email = "This field is required";
    }

    if (!values.password) {
      errors.password = "This field is required";
    }

    setErrors(errors);

    const keys = Object.keys(errors);

    if (keys.length) {
      return "Please fix validation errors";
    }
  };

  const login = async () => {
    try {
      const validation = validate();

      if (validation) {
        return;
      }

      setLoading(true);

      const payload = {
        email: values.email,
        password: values.password,
      };
      const res = await api.login(payload);
      const isSuperAdmin = res.data.user.roles.find(
        (item) => item.role.code === "super-admin"
      );
      const isAdmin = res.data.user.roles.find(
        (item) => item.role.code === "admin"
      );

      if (!isAdmin) {
        throw new Error("You need to be an admin to continue");
      }

      if (isSuperAdmin) {
        res.data.user.isSuperAdmin = true;
      } else {
        res.data.user.isSuperAdmin = false;
      }

      dispatch(setUser(res.data));

      axios.defaults.headers.common["Authorization"] = res.data.authToken;

      if (res.data.user.changedFirstPassword) {
        history.replace("/dashboard");
      } else {
        setFirstPasswordModal({
          open: true,
          data: {
            password: values.password,
            token: res.data.authToken,
          },
        });
      }
    } catch (e) {
      setMessage({
        isError: true,
        title: "Error",
        message: e?.message,
      });
    }

    setValues({ ...values, password: "" });
    setLoading(false);
  };

  const handleCloseModal = () => {
    setMessage({});
  };

  const navigateToForgotPassword = () => {
    history.push("/forgot-password");
  };

  const handleChangePassword = async (data) => {
    try {
      setLoading(true);

      const payload = {
        currentPassword: data.password,
        newPassword: data.newPassword,
      };

      await api.changePassword(payload);

      setMessage({
        isError: false,
        title: "Success",
        message:
          "Password has been changed successfully. Please re-login to your account.",
      });
      setFirstPasswordModal({});
    } catch (e) {
      setMessage({
        isError: true,
        title: "Error",
        message: e?.message,
      });
    }

    setLoading(false);
  };

  return (
    <div className={classes.loginContainer}>
      {loading && <Loader />}
      <MessageModal data={message} handleClose={handleCloseModal} />
      <LoginBackground />
      <div className={classes.container}>
        <div className={classes.containerLeft}>
          <div className={classes.leftContainerContent}>
            <div className={classes.soladLogoContainer}>
              <img
                alt="solad-logo"
                className={classes.soladLogo}
                src={SoladLogo}
              />
            </div>
            <form onSubmit={handleSubmit} className={classes.form}>
              <div className={classes.inputFieldContainer}>
                <span className={classes.label}>Email</span>
                <OutlinedInput
                  errorMessage={errors.email}
                  value={values.email}
                  className={classes.inputField}
                  fullWidth
                  onChange={handleChange("email")}
                />
              </div>
              <div className={classes.inputFieldContainer}>
                <span className={classes.label}>Password</span>
                <OutlinedInput
                  errorMessage={errors.password}
                  fullWidth
                  value={values.password}
                  onChange={handleChange("password")}
                  inputType="password"
                />
              </div>
              <div className="text-right">
                <span
                  onClick={navigateToForgotPassword}
                  className={classes.containerLeftText3}
                >
                  Forgot your password?
                </span>
              </div>
              <div className={classes.signInBtnBox}>
                <Button type="submit" title="Sign In" theme="primary" />
              </div>
            </form>
          </div>
        </div>
        <div className={classes.containerRight}>
          <div className={classes.rightContainerContent}>
            <div className={classes.sologanBox}>
              <p className={classes.sologanText}>Power</p>
              <p className={classes.sologanText}>Growth</p>
              <p className={classes.sologanText}>Inclusion</p>
            </div>
            <p className={classes.appName}>Solad Admin Portal</p>
          </div>
          <img
            alt=""
            className={classes.containerRightBackground}
            src={LoginContainerRightBackground}
          />
        </div>
      </div>
      <ChangeFirstPasswordModal
        onClose={() => {
          setFirstPasswordModal({});
        }}
        onSubmit={handleChangePassword}
        open={firstPasswordModal.open}
        data={firstPasswordModal.data}
      />
    </div>
  );
};

export default Login;
