import { makeStyles } from "@material-ui/core/styles";

import colors from "../../config/colors";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.background,
    height: "100%",
    display: "flex",
    alignItems: "center",
    padding: "30px 30px",
  },
  container: {
    width: "100%",
    height: "100%",
    background: theme.palette.white,
    padding: theme.spacing(3),
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
  },
  headerContainer: {
    width: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    padding: theme.spacing(3),
  },
  contentContainer: {
    flex: 1,
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 800,
    width: "100%",
  },
  title: {
    fontSize: "1.7em",
  },
  codeInputContainer: {
    marginTop: theme.spacing(1),
  },
  instruction: {
    marginTop: theme.spacing(2),
    fontSize: "1.2em",
  },
}));

export const codeInputStyles = {
  fontFamily: "monospace",
  margin: "4px",
  MozAppearance: "textfield",
  paddingLeft: "7px",
  color: colors.BLACK,
  border: `1px solid ${colors.MEDIUM_GREY}`,
  width: 60,
  height: 60,
  borderRadius: 60,
  outline: "none",
  marginLeft: "5px",
  marginRight: "5px",
  fontSize: "3.5em",
  textAlign: "center",
};

export default useStyles;
