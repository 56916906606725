import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    width: 40,
    height: 40,
    borderRadius: 40,
    background: theme.palette.secondary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    cursor: "pointer"
  },
  icon: {
    width: "50%",
    height: "50%",
  },
}));

export default useStyles;
