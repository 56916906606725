import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    border: "1px solid",
    borderColor: theme.palette.lightGrey,
    borderRadius: "10px"
  },
  tabContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  tabBox: {
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer"
  },
  activeDot: {
    backgroundColor: theme.palette.lightOrange,
    width: 10,
    height: 10,
    borderRadius: 5,
    position: "absolute",
    bottom: -5
  },
  tabName: {
    fontWeight: "bold"
  }
}));

export default useStyles;
