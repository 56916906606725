import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    maxWidth: "max-content",
    cursor: "pointer",
  },
  box: {
    width: "30px",
    height: "30px",
    border: `1px solid ${theme.palette.lightGrey}`,
    marginRight: theme.spacing(1),
    borderRadius: "5px",
  },
  checked: {
    backgroundColor: theme.palette.orange,
    width: "30px",
    height: "30px",
    border: `1px solid ${theme.palette.orange}`,
    marginRight: theme.spacing(1),
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  disabledChecked: {
    opacity: 0.4,
    cursor: "pointer",
  },
  disabledLabel: {
    opacity: 0.4,
    cursor: "pointer",
  },
  label: {
    fontSize: "1.3em",
  },
  icon: {
    width: "70%",
    height: "70%",
  },
}));

export default useStyles;
