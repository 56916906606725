import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    justifyContent: "space-around",
    border: `1px solid ${theme.palette.lightGrey}`,
    marginTop: "1em",
    textAlign: "center",
    borderRadius: "6px",
    height: "45px",
    alignItems: "center",
  },
  pictureSection: {
    width: "80px",
  },
  dataSection: {
    flex: 1,
  },
  deleteIcon: {
    width: "17px",
    height: "17px"
  }
}));

export default useStyles;
