import React, { useState } from "react";
import { Modal, Fade } from "@material-ui/core";

import CrossBlackIcon from "../../assets/icons/circleCrossBlack.png";
import OutlinedInput from "../OutlinedInput/index.js";
import Button from "../Button/index.js";
import useStyles from "./style.js";

const ChangeFirstPasswordModal = (props) => {
  const [errors, setErrors] = useState({});
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const classes = useStyles();

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validation = validate();

    if (validation) {
      return;
    }

    if (props.onSubmit) {
      props.onSubmit({ newPassword, password: props.data.password });
    }
  };

  const validate = () => {
    const password1 = newPassword.trim();
    const password2 = confirmNewPassword.trim();
    const errors = {};

    if (!password1) {
      errors.newPassword = "Please enter your new password";
    }

    if (!password2) {
      errors.confirmNewPassword = "Please confirm your new password";
    }

    if (password1 && password2 && password1 !== password2) {
      errors.newPassword = "Both password should be same";
      errors.confirmNewPassword = "Both password should be same";
    }

    setErrors(errors);
    return Object.keys(errors).length;
  };

  return (
    <Modal
      className={classes.modal}
      open={props.open}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade timeout={{ appear: 0, enter: 500 }} in={props.open}>
        <div className={`${classes.container} box-shadow`}>
          <div className={classes.headerContainer}>
            <p className={classes.title}>Set your new password</p>
            <img
              className={classes.crossIcon}
              src={CrossBlackIcon}
              onClick={handleClose}
              alt="circle-black"
            />
          </div>
          <form onSubmit={handleSubmit} className={classes.form}>
            <OutlinedInput
              label="New Password"
              type="password"
              inputType="password"
              errorMessage={errors.newPassword}
              value={newPassword}
              fullWidth
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <div className={classes.verticalSeperator}></div>
            <OutlinedInput
              label="Confirm Password"
              type="password"
              inputType="password"
              errorMessage={errors.confirmNewPassword}
              value={confirmNewPassword}
              fullWidth
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
            <div className={classes.verticalSeperator}></div>

            <div className={classes.buttonContainer}>
              <Button type="submit" title="Submit" theme="primary" />
            </div>
          </form>
        </div>
      </Fade>
    </Modal>
  );
};

export default ChangeFirstPasswordModal;
