import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.white,
    width: "100%",
    height: "150px",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  contentContainer: {
    flex: 1,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  button: {
    width: "120px",
  },
  secondButton: {
    marginLeft: theme.spacing(1),
  },
  text: {
    fontSize: "1.3rem",
  },
}));

export default useStyles;
