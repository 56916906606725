import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: "16px",
    fontWeight: 500,
  },
  loadContainer: {
    backgroundColor: "#F0F4F3",
    padding: "1em",
    paddingLeft: "1em",
    paddingRight: "1em",
    borderRadius: "10px",
    display: "flex",
  },
  loadLabel: {
    fontSize: "16px",
    flex: 1,
  },
  loadLink: {
    cursor: "pointer",
  },
  modalContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.white,
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    outline: "none",
    position: "relative",
    overflow: "auto",
    width: "50%",
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    maxHeight: "500px",
  },
  buttonContainer: {
    alignSelf: "flex-end",
  },
  listContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflowY: "hidden"
  },
  listHeadingItem: {
    display: "flex",
    backgroundColor: "rgba(0,0,0,0.1)",
    padding: theme.spacing(1),
  },
  listHeadingLeft: {
    flex: 1,
    fontWeight: 500,
  },
  listHeadingRight: {
    flex: 1,
    fontWeight: 500,
  },
  listItemContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflowY: "auto",
  },
  listItem: {
    display: "flex",
    padding: theme.spacing(1),
  },
  listLeft: {
    flex: 1,
  },
  listRight: {
    flex: 1,
  },
}));

export default useStyles;
