import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.background,
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  container: {
    width: "100%",
    height: "100%",
    padding: "30px 30px",
  },
  frameContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  contentContainer: {
    backgroundColor: "white",
    width: "100%",
    padding: theme.spacing(3),
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
  },
  dataMainContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    paddingTop: theme.spacing(2),
  },
  dataLeftContainer: {
    flex: 1,
    marginRight: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  dataRightContainer: {
    width: "250px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  belowBarChartContainer: {
    marginTop: theme.spacing(2),
    flex: 1,
    display: "flex",
    flexDirection: "row",
  },
  pieChartContainer: {
    flex: 1,
    maxWidth: "300px",
    marginRight: theme.spacing(2),
  },
  secondBarChartContainer: {
    flex: 1,
  },
  belowDataRightContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(2),
  },
  cashReceivedContainer: {
    marginBottom: theme.spacing(1),
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  cashReceivableContainer: {
    marginTop: theme.spacing(1),
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
}));

export default useStyles;
