import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    alignItems: "center",
  },
  leftArrow: {
    width: 25,
    height: 25,
  },
  label: {
    fontSize: "1.5em",
  },
}));

export default useStyles;
