import React, { useState, useEffect } from "react";

import Spinner from "../Spinner";
import Chart from "./Chart";
import useStyles from "./style";

const CashReceivableBarChart = (props) => {
  const classes = useStyles();
  const [xaxis, setXaxis] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props.data && props.data.length) {
      const newXAxis = props.data.map((item) => item.name);
      const newData = props.data.map((item) => item.amount);

      setXaxis(newXAxis);
      setData(newData);
    }
  }, [props.data]);

  return (
    <div className={classes.container}>
      {props.loading ? (
        <div className={classes.loaderContainer}>
          <Spinner size="60px" />
        </div>
      ) : (
        <>
          <div className={classes.chartHeadingContainer}>
            <span className={classes.chartHeading}>
              Cash Receivable By Client Type
            </span>
          </div>
          <div className={classes.chartContainer}>
            {xaxis.length && <Chart xaxis={xaxis} data={data} />}
          </div>
        </>
      )}
    </div>
  );
};

export default CashReceivableBarChart;
