import React from "react";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { MenuItem } from "@material-ui/core";

import useStyles from "./style.js";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultipleSelect = (props) => {
  const classes = useStyles();
  const { value = [] } = props;
  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange(e.target.value);
      // setValue(e.target.value)
    }
  };

  return (
    <FormControl className={classes.formControl}>
      <Select
        labelId="demo-mutiple-name-label"
        id="demo-mutiple-name"
        multiple
        className={classes.select}
        value={value}
        onChange={handleChange}
        input={<Input />}
        MenuProps={MenuProps}
      >
        {props.options.map((item) => (
          <MenuItem
            key={item._id}
            value={item._id}
            className={classes.input}
            // style={getStyles(item, value, theme)}
          >
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

MultipleSelect.defaultProps = {
  options: [],
  // placeholder: "Select",
  // style: {},
};

export default MultipleSelect;
