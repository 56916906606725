import React from "react";

import Spinner from "../../../components/Spinner";
import globalHelpers from "../../../utils/globalHelpers";
import NigeriaBlueIcon from "../../../assets/icons/nigeria-blue.png";
import colors from "../../../config/colors";
import HorizontalSeperator from "../../../components/HorizontalSeperator";
import useStyles from "./style";

const CashReceivedCard = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {props.loading ? (
        <div className={classes.loaderContainer}>
          <Spinner size="30px" />
        </div>
      ) : (
        <>
          <div className={classes.chartHeadingContainer}>
            <span className={classes.chartHeading}>Cash Received</span>
            <HorizontalSeperator color={colors.GREY} />
          </div>
          <div className={classes.chartContainer}>
            <div className={classes.dataContainer}>
              <img
                alt="nigeria-blue"
                className={classes.nigerianSymbolIcon}
                src={NigeriaBlueIcon}
              />
              <div className={classes.valueDataContainer}>
                {/* <span className={classes.labelText}>Total amount</span> */}
                <span className={classes.valueText}>
                  {globalHelpers.formatCurrency(props.data)}
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

CashReceivedCard.defaultProps = {
  data: 0,
};

export default CashReceivedCard;
