import React, { useState, useEffect } from "react";

import Loader from "../../components/Loader";
import MessageModal from "../../components/MessageModal";
import { useHistory } from "react-router";
import OutlinedInput from "../../components/OutlinedInput";
import Button from "../../components/Button";
import BackButton from "../../components/BackButton";
import useStyles from "./style";
import api from "../../utils/api";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    isError: false,
    title: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const classes = useStyles();
  const history = useHistory();

  const navigationParams = history?.location?.state || {};

  useEffect(() => {
    if (!navigationParams.email) {
      history.replace("/forgot-password");
    }
  }, [history, navigationParams.email]);

  const handleChange = (label, value) => {
    if (label === "password") {
      setPassword(value);
    }

    if (label === "confirmPassword") {
      setConfirmPassword(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const validationErrors = validation();

      if (validationErrors) {
        return;
      }

      setLoading(true);

      const payload = {
        email: navigationParams.email,
        newPassword: password,
      };

      await api.resetPassword(payload);
      history.replace("/");
    } catch (e) {
      setMessage({
        isError: true,
        title: "Error",
        message: e?.message,
      });
    }

    setLoading(false);
  };

  const validation = () => {
    const errors = {};

    if (!password) {
      errors.password = "Please enter your new password";
    }

    if (!confirmPassword) {
      errors.confirmPassword = "Please confirm your new password";
    }

    if (password && confirmPassword && password !== confirmPassword) {
      errors.password = "Passwords doesn't match";
      errors.confirmPassword = "Passwords doesn't match";
    }

    setErrors(errors);
    return Object.keys(errors).length;
  };

  const handleCloseModal = () => {
    setMessage({
      isError: false,
      title: "",
      message: "",
    });
  };

  return (
    <>
      <div className={classes.mainContainer}>
        <div className={`${classes.container} box-shadow`}>
          <div className={classes.headerContainer}>
            <BackButton />
          </div>
          <div className={classes.contentContainer}>
            <p className={classes.title}>Create New Password</p>
            <form onSubmit={handleSubmit} className={classes.form}>
              <OutlinedInput
                autoFocus={true}
                label="Password"
                type="password"
                inputType="password"
                errorMessage={errors.password}
                value={password}
                fullWidth
                onChange={(e) => handleChange("password", e.target.value)}
              />
              <div className={classes.seperator}></div>
              <OutlinedInput
                label="Confirm Password"
                type="password"
                inputType="password"
                errorMessage={errors.confirmPassword}
                value={confirmPassword}
                fullWidth
                onChange={(e) =>
                  handleChange("confirmPassword", e.target.value)
                }
              />
              <div className={classes.buttonContainer}>
                <Button type="submit" title="Reset password" theme="primary" />
              </div>
            </form>
          </div>
        </div>
      </div>
      {loading && <Loader />}
      <MessageModal data={message} handleClose={handleCloseModal} />
    </>
  );
};

export default ResetPassword;
