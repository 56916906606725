import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";

import useStyles from "./style";
import MessageModal from "../../components/MessageModal";
import AppFrame from "../../components/AppFrame";
import AppHeader from "../../components/AppHeader";
import Loader from "../../components/Loader";
import api from "../../utils/api";
import OutlinedInput from "../../components/OutlinedInput";
import Button from "../../components/Button";

const AddSubCategory = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const [values, setValues] = useState({
    name: null,
    description: null,
  });

  useEffect(() => {
    if (props.location.value) {
      fetchEditQuestionDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchEditQuestionDetails = async () => {
    setValues({
      name: props.location.value.name,
      description: props.location.value.description,
    });
  };

  const handleChange = (prop) => (event) => {
    const previousValuesState = { ...values };

    setValues({ ...previousValuesState, [prop]: event.target.value });
  };

  const handleCloseModal = () => {
    setMessage({});
  };

  const saveSubCategory = async () => {
    try {
      let message = "Sub Category created successfully";

      const validation = validate();

      if (validation) {
        throw new Error(validation);
      }

      setLoading(true);

      const payload = {
        name: values.name,
        description: values.description,
        code: values.name.toLowerCase(),
      };

      if (props.location.value) {
        payload._id = props.location.value._id;
        message = "Sub Category edited successfully";
        await api.editSubCategories(payload);
      } else {
        await api.createSubCategories(payload);
      }

      setMessage({
        isError: false,
        title: "Success",
        message: message,
        onConfirm: () => {
          history.push("/sub-categories");
        },
      });
    } catch (e) {
      setMessage({
        isError: true,
        title: "Error",
        message: e?.message,
      });
    }

    setLoading(false);
  };

  const validate = () => {
    const errors = {};
    let errorMessage = "This field is required";

    if (!values.name) {
      errors.name = errorMessage;
    }

    setErrors(errors);

    const keys = Object.keys(errors);

    if (keys.length) {
      return "Please fix validation errors";
    }
  };

  return (
    <div className={classes.mainContainer}>
      {loading && <Loader />}
      <MessageModal data={message} handleClose={handleCloseModal} />
      <div className={classes.container}>
        <AppFrame>
          <div className={classes.contentContainer}>
            <AppHeader />
            <div className={classes.headerContainer}>
              <div className={classes.headingContainer}>
                <h1 className={classes.heading}>
                  {props.location.value
                    ? "Edit Sub Category"
                    : "Add Sub Category"}
                </h1>
              </div>
              <div className={classes.horizontalDividerContainer}>
                <hr className={classes.horizontalDivider} />
              </div>
            </div>
            <div className={classes.inputContenttContainer}>
              <div className={classes.inputHeadingContainer}>
                <h1 className={classes.inputHeading}>Enter details </h1>
              </div>
              <div className={classes.inputFieldsContainer}>
                <span className={classes.label}>Name</span>
                <OutlinedInput
                  fullWidth={true}
                  onChange={handleChange("name")}
                  value={values.name}
                  errorMessage={errors.name}
                />

                <span className={classes.label}>Description</span>
                <div>
                  <OutlinedInput
                    fullWidth={true}
                    onChange={handleChange("description")}
                    value={values.description}
                  />
                </div>

                <div className={classes.buttonContainer}>
                  <div className={classes.button}>
                    <Button
                      type="button"
                      title="Save"
                      theme="primary"
                      onClick={saveSubCategory}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AppFrame>
      </div>
    </div>
  );
};

export default AddSubCategory;
