import { makeStyles } from "@material-ui/core/styles";
import colors from "../../config/colors";

const useStyles = makeStyles((theme) => ({
  select: {
    width: "100%",
    padding: "0.9em",
    paddingLeft: "0.7em",
    paddingRight: "0.7em",
    fontSize: "1.1em",
    outline: "none",
    border: "none",
    backgroundColor: colors.BACKGROUND,
    borderRadius: 9,
  },
  label: {
    fontSize: "16px",
    fontWeight: 500,
  },
  formHelperText: {
    fontSize: "1em",
  },
}));

export default useStyles;
