import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    backgroundColor: theme.palette.white,
    borderColor: theme.palette.grey,
    borderWidth: 2,
    borderStyle: "solid",
    borderRadius: "15px",
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  chartHeadingContainer: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(0.5),
  },
  chartHeading: {
    fontSize: "1.2em",
    fontWeight: "bold",
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(1),
  },

  chartContainer: {
    flex: 1,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  dataContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  nigerianSymbolIcon: {
    width: 35,
    height: 35,
  },
  labelText: {
    fontSize: "0.6em",
    color: theme.palette.darkGrey,
  },
  valueText: {
    fontSize: "1.1em",
    lineHeight: 1.1,
    fontWeight: "bold",
  },
  valueDataContainer: {
    display: "flex",
    flexDirection: "column",
  },
  loaderContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default useStyles;
