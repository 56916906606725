import { SET_USER_DATA, CLEAR_USER_DATA } from "./action";

const initialState = {
  admin: {
    data: [],
    page: 1,
    totalPage: 1,
  },
  agent: {
    data: [],
    page: 1,
    totalPage: 1,
  },
  user: {
    data: [],
    page: 1,
    totalPage: 1,
  },
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_USER_DATA:
      return initialState;
    default:
      return state;
  }
}

export default reducer;
