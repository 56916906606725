import React, { useState, useEffect } from "react";
import useStyles from "./style";
import chunk from "lodash/chunk";

const FilterDropdown = (props) => {
  const { filters, onFilterChange, selectedFilter } = props;
  const classes = useStyles();
  const [filtersArr, setFiltersArr] = useState([]);

  useEffect(() => {
    arrangeFilters();
  }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

  const arrangeFilters = () => {
    const arrangedFilters = chunk(filters, 3);
    const finalArr = arrangedFilters.map((item) => {
      if (item.length === 1) {
        item.push(null, null);
      }
      if (item.length === 2) {
        item.push(null);
      }
      return item;
    });
    setFiltersArr(finalArr);
  };

  const onFilterClick = (filter) => {
    onFilterChange(filter);
  };

  return (
    <div className={classes.mainContainer}>
      <h2 className={classes.title}>Select Filter Type</h2>
      <div className={classes.filterBox}>
        {filtersArr.map((filter) => (
          <div className={classes.row}>
            {filter.map((filterText, index) => (
              <div className={classes.filterItem}>
                {index !== 0 && filterText && (
                  <div className={classes.border} />
                )}
                <div
                  className={classes.filterTextBox}
                  onClick={() => onFilterClick(filterText)}
                >
                  <p className={classes.filter}>{filterText?.name}</p>
                  <div
                    className={`${classes.selectedBorder} ${
                      selectedFilter !== filterText?.name && classes.hidden
                    }`}
                  />
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilterDropdown;
