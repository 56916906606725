import { makeStyles } from "@material-ui/core/styles";
import colors from "../../config/colors";

const useStyles = makeStyles(() => ({
  label: {
    fontSize: "16px",
    fontWeight: 500
  },
  inputField: {
    backgroundColor: colors.BACKGROUND,
    borderRadius: "0.5em",
    padding: "0.5em",
    paddingLeft: "1em",
    paddingRight: "1em",
  },
  formHelperText: {
    fontSize: "1em",
  },
}));

export default useStyles;
