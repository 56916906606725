import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    height: 1,
    width: "100%",
    background: theme.palette.black,
  },
}));

export default useStyles;
