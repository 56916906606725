import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    minHeight: "300px",
    backgroundColor: theme.palette.white,
    borderColor: theme.palette.grey,
    borderWidth: 2,
    borderStyle: "solid",
    borderRadius: "15px",
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  chartHeadingContainer: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(0.5),
    alignItems: "center",
  },
  chartHeading: {
    fontSize: "1.2em",
    fontWeight: "bold",
    color: theme.palette.secondary.main,
  },
  chartContainer: {
    flex: 1,
    paddingRight: theme.spacing(2),
  },
  chartInfoContainer: {
    width: "100%",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(1.5),
    display: "flex",
    flexDirection: "row",
  },
  chartInfoLeftContainer: {
    display: "flex",
    flexDirection: "row",
    // borderRightColor: theme.palette.black,
    // borderRightWidth: 1,
    // borderRightStyle: "solid",
  },
  insideHeaderRightContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    marginLeft: theme.spacing(1),
  },
  selectInput: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
    maxWidth: 180,
  },
  loadingContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
