import React, { useState, useEffect, useReducer } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import authActions from "../../redux/auth/action";
import api from "../../utils/api";
import constants from "../../config/constants";
import SelectInput from "../../components/SelectInput";
import OutlinedInput from "../../components/OutlinedInput";
import Button from "../../components/Button";
import Avatar from "../../components/Avatar";
import Loader from "../../components/Loader";
import MessageModal from "../../components/MessageModal";
import AppFrame from "../../components/AppFrame";
import useStyles from "./style";
import globalHelpers from "../../utils/globalHelpers";

const initialState = {
  user: {},
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };

    default:
      return state;
  }
}

const EditProfile = (props) => {
  const classes = useStyles();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();

  useEffect(() => {
    dispatch({
      type: "SET_USER",
      payload: { email: props.user.email, ...props.user.profile },
    });
  }, [props.user.email, props.user.profile]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const validationErrors = validation();

      if (validationErrors) {
        return;
      }

      setLoading(true);

      const payload = {
        phone: state.user.phone,
        firstName: state.user.firstName,
        lastName: state.user.lastName,
        middleName: state.user.middleName,
        gender: state.user.gender,
      };

      if (state.user.pictureFile) {
        const formData = new FormData();
        formData.append("image", state.user.pictureFile);
        formData.append("pathPrefix", "admin/");
        const response = await api.uploadImage(formData);
        console.log("response =>", response);

        payload.picture = response.data.file.filename;
      }

      const response = await api.editProfile(payload);
      const isSuperAdmin = response.data.user.roles.find(
        (item) => item.role.code === "super-admin"
      );

      props.setUser({ user: { ...response.data.user, isSuperAdmin } });
      setMessage({
        isError: false,
        title: "Success",
        message: "Profile updated",
        onConfirm: () => {
          history.push("/dashboard");
        },
      });
    } catch (e) {
      setMessage({
        isError: true,
        title: "Error",
        message: e?.message,
      });
    }

    setLoading(false);
  };

  const handleCloseModal = () => {
    setMessage({});
  };

  const validation = () => {
    const errors = {};

    if (globalHelpers.isBlankString(state.user.firstName)) {
      errors.firstName = "This field is required";
    }

    if (globalHelpers.isBlankString(state.user.lastName)) {
      errors.lastName = "This field is required";
    }

    if (globalHelpers.isBlankString(state.user.email)) {
      errors.email = "This field is required";
    }

    if (globalHelpers.isBlankString(state.user.phone)) {
      errors.phone = "This field is required";
    }

    setErrors(errors);
    return Object.keys(errors).length;
  };

  const handleChange = (label, value) => {
    dispatch({ type: "SET_USER", payload: { [label]: value } });
  };

  const handleAvatarChange = (data, file) => {
    dispatch({
      type: "SET_USER",
      payload: {
        picture: data.base64,
        pictureName: data.name,
        pictureFile: file,
      },
    });
  };

  return (
    <>
      <div className={classes.mainContainer}>
        <div className={classes.container}>
          <AppFrame name="edit profile">
            <div className={classes.frameContainer}>
              <div className={classes.contentContainer}>
                <span className={classes.title}>Profile</span>
                <div className={classes.dataContainer}>
                  <Avatar
                    editable={true}
                    onAvatarChange={handleAvatarChange}
                    source={state.user.picture}
                    size="130px"
                  />
                  <form className={classes.form} onSubmit={handleSubmit}>
                    <div className={classes.formRow}>
                      <OutlinedInput
                        errorMessage={errors.firstName}
                        value={state.user.firstName}
                        fullWidth
                        label="First Name"
                        onChange={(e) =>
                          handleChange("firstName", e.target.value)
                        }
                      />
                      <div className={classes.seperator}></div>
                      <OutlinedInput
                        errorMessage={errors.middleName}
                        value={state.user.middleName}
                        fullWidth
                        label="Middle Name"
                        onChange={(e) =>
                          handleChange("middleName", e.target.value)
                        }
                      />
                    </div>
                    <div className={classes.formRow}>
                      <OutlinedInput
                        errorMessage={errors.lastName}
                        value={state.user.lastName}
                        fullWidth
                        label="Last Name"
                        onChange={(e) =>
                          handleChange("lastName", e.target.value)
                        }
                      />
                      <div className={classes.seperator}></div>
                      <SelectInput
                        errorMessage={errors.gender}
                        label="Gender"
                        placeholder="Select gender"
                        options={constants.GENDERS}
                        value={state.user.gender}
                        onChange={(e) => handleChange("gender", e)}
                      />
                    </div>
                    <div className={classes.formRow}>
                      <OutlinedInput
                        errorMessage={errors.email}
                        value={state.user.email}
                        fullWidth
                        disabled={true}
                        label="Email"
                        onChange={(e) => handleChange("email", e.target.value)}
                      />
                      <div className={classes.seperator}></div>
                      <OutlinedInput
                        errorMessage={errors.phone}
                        value={state.user.phone}
                        fullWidth
                        label="Phone Number"
                        onChange={(e) => handleChange("phone", e.target.value)}
                      />
                    </div>
                    <div className={classes.buttonContainer}>
                      <Button type="submit" title="Submit" theme="primary" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </AppFrame>
        </div>
      </div>
      {loading && <Loader />}
      <MessageModal data={message} handleClose={handleCloseModal} />
    </>
  );
};

const mapStateToProps = (store) => ({
  user: store.auth.user,
});

const mapDispatchToProps = {
  setUser: authActions.setUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
