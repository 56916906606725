import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.background,
    height: "100%",
  },
  container: {
    width: "100%",
    height: "100%",
    padding: "30px 30px",
  },
  contentContainer: {
    padding: theme.spacing(3),
    position: "relative",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  headerContainer: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    width: "100%",
    display: "flex",
    alignItem: "center",
    flexDirection: "row",
  },
  headingContainer: {
    width: "auto",
    display: "flex",
    alignItem: "center",
    flexDirection: "row",
    color: theme.palette.orange,
    paddingRight: theme.spacing(2),
  },
  heading: {
    fontSize: "2em",
  },
  horizontalDividerContainer: {
    width: "100%",
    display: "flex",
    alignItem: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  horizontalDivider: {
    border: `0.7 solid ${theme.palette.darkGrey}`,
    width: "100%",
  },
  inputContenttContainer: {
    display: "flex",
    flexDirection: "column",
    alignItem: "center",
    width: "100%",
    padding: theme.spacing(2),
    borderRadius: "20px",
    overflow: "auto",
  },
  inputHeadingContainer: {
    paddingBottom: theme.spacing(2),
  },
  inputHeading: {
    fontSize: "2em",
  },
  inputFieldsContainer: {
    overflow: "auto",
    padding: theme.spacing(1),
  },
  label: {
    fontSize: "1em",
    fontWeight: "500",
    lineHeight: "3",
    zindex: -10000,
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItem: "center",
    justifyContent: "center",
  },
  button: {
    width: "20%",
    paddingTop: theme.spacing(5),
  },
  addButtonContainer: {
    position: "absolute",
    bottom: "10%",
    right: 0,
    display: "flex",
    flexDirection: "row",
    alignItem: "center",
    justifyContent: "center",
    width: "10%",
    height: "6%",
    fontSize: "1em",
  },
  multipleSelect: {
    zIndex: 1000,
  },
  inlineInputs: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  inlineInputLables: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  inlinePaddingLeft: {
    paddingRight: theme.spacing(2),
  },
  inlinePaddingTop: {
    paddingTop: theme.spacing(2),
  },
  inlinePaddingBottom: {
    paddingBottom: theme.spacing(2),
  },
  inputContainer: {
    marginTop: theme.spacing(3),
  },
}));

export default useStyles;
