import { makeStyles } from "@material-ui/core/styles";
import colors from "../../config/colors";

const useStyles = makeStyles((theme) => ({
  button: ({ theme: btnTheme }) => {
    let backgroundColor = colors.WHITE, color = colors.ORANGE;

    if(btnTheme === "primary") {
      backgroundColor = colors.ORANGE;
      color = colors.WHITE
    };

    if(btnTheme === "danger") {
      backgroundColor = colors.RED;
      color = colors.WHITE
    }

    return {
      backgroundColor: backgroundColor,
      color: color,
      width: "100%",
      borderRadius: "9px",
      fontSize: "1.2em",
      border: "1px solid",
      borderColor: backgroundColor,
      padding: theme.spacing(1),
      cursor: "pointer",
    };
  },
}));

export default useStyles;
