const constants = {
  BASE_URL: "https://api.soladenergy.com/api",
  BASE_URL_ROOT: "https://api.soladenergy.com",
  CURRENCY_SYMBOL: {
    NIGERIAN: "₦",
  },
  EMAIL_REGEX:
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line
  MONTHS_SHORT: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  MONTHS_FOR_CHART: [
    {
      label: "All",
      value: "all",
    },
    {
      label: "January",
      value: 1,
    },
    {
      label: "February",
      value: 2,
    },
    {
      label: "March",
      value: 3,
    },
    {
      label: "April",
      value: 4,
    },
    {
      label: "May",
      value: 5,
    },
    {
      label: "June",
      value: 6,
    },
    {
      label: "July",
      value: 7,
    },
    {
      label: "August",
      value: 8,
    },
    {
      label: "September",
      value: 9,
    },
    {
      label: "October",
      value: 10,
    },
    {
      label: "November",
      value: 11,
    },
    {
      label: "December",
      value: 12,
    },
  ],
  GENDERS: [
    { value: "m", label: "Male" },
    { value: "f", label: "Female" },
  ],
  YES_NO_OPTIONS: [
    { value: "true", label: "Yes" },
    { value: "false", label: "No" },
  ],
  ADMIN_EMAIL: ["izaanjahangir2@gmail.com"],
};

export default constants;
