import React, { useState } from "react";
import InputBase from "@material-ui/core/InputBase";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import useStyles from "./style.js";

const OutlinedInput = (props) => {
  const {
    fullWidth,
    type = "text",
    onChange,
    containerStyle,
    disabled = false,
  } = props;
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const decideTypeForPassword = () => {
    return showPassword ? "text" : "password";
  };

  return (
    <div className="w-100" style={containerStyle}>
      {!!props.label && <p className={classes.label}>{props.label}</p>}
      <InputBase
        autoFocus={props.autoFocus}
        error={!!props.errorMessage}
        className={classes.inputField}
        fullWidth={fullWidth}
        type={props.inputType === "password" ? decideTypeForPassword() : type}
        value={props.value}
        disabled={disabled}
        onChange={onChange}
        endAdornment={
          props.inputType === "password" && (
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }
      />
      <FormHelperText
        className={classes.formHelperText}
        error={!!props.errorMessage}
      >
        {props.errorMessage}
      </FormHelperText>
    </div>
  );
};

OutlinedInput.defaultProps = {
  value: "",
  autoFocus: false,
  inputType: "text",
  disabled: false,
};

export default OutlinedInput;
