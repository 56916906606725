import { FormHelperText } from "@material-ui/core";

import useStyles from "./style";

const SelectInput = (props) => {
  const classes = useStyles();

  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange(e.target.value);
    }
  };

  return (
    <div className="w-100">
      {!!props.label && <p className={classes.label}>{props.label}</p>}
      <select
        value={props.value}
        onChange={handleChange}
        style={props.style}
        className={classes.select}
        disabled={props.disabled}
      >
        <option value="" selected disabled></option>
        {props.options.map((item) => (
          <option
            key={item.value ? item.value : item.name}
            value={item.value ? item.value : item._id}
          >
            {item.label ? item.label : item.name}
          </option>
        ))}
      </select>
      <FormHelperText
        className={classes.formHelperText}
        error={!!props.errorMessage}
      >
        {props.errorMessage}
      </FormHelperText>
    </div>
  );
};

SelectInput.defaultProps = {
  options: [],
  placeholder: "Select",
  style: {},
};

export default SelectInput;
