import React, { useState } from "react";
import { useHistory } from "react-router";

import Loader from "../../components/Loader";
import MessageModal from "../../components/MessageModal";
import api from "../../utils/api";
import OutlinedInput from "../../components/OutlinedInput";
import Button from "../../components/Button";
import BackButton from "../../components/BackButton";
import useStyles from "./style";

const ForgotPassword = () => {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    isError: false,
    title: "",
    message: "",
  });
  const [email, setEmail] = useState("");
  const history = useHistory();
  const classes = useStyles();

  const handleChange = (label, value) => {
    if (label === "email") {
      setEmail(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const validationErrors = validation();

      if (validationErrors) {
        return;
      }

      setLoading(true);
      const payload = {
        email,
      };

      await api.forgotPassword(payload);

      history.push("/verify-otp", { email });
    } catch (e) {
      setMessage({
        isError: true,
        title: "Error",
        message: e?.message,
      });
    }

    setLoading(false);
  };

  const validation = () => {
    const errors = {};

    if (!email) {
      errors.email = "Please enter your email";
    }

    setErrors(errors);
    return Object.keys(errors).length;
  };

  const handleCloseModal = () => {
    setMessage({
      isError: false,
      title: "",
      message: "",
    });
  };

  return (
    <>
      <div className={classes.mainContainer}>
        <div className={`${classes.container} box-shadow`}>
          <div className={classes.headerContainer}>
            <BackButton />
          </div>
          <div className={classes.contentContainer}>
            <p className={classes.title}>Reset Password</p>
            <p className={classes.instructions}>
              Enter email address associated with your account to receive OTP
              for resetting your password
            </p>
            <form onSubmit={handleSubmit} className={classes.form}>
              <OutlinedInput
                autoFocus={true}
                label="Email Address"
                errorMessage={errors.email}
                value={email}
                fullWidth
                onChange={(e) => handleChange("email", e.target.value)}
              />
              <div className={classes.buttonContainer}>
                <Button type="submit" title="Send OTP" theme="primary" />
              </div>
            </form>
          </div>
        </div>
      </div>
      {loading && <Loader />}
      <MessageModal data={message} handleClose={handleCloseModal} />
    </>
  );
};

export default ForgotPassword;
