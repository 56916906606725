import React, { useState, useEffect } from "react";
import Image from "../Image";

const loaderContainerStyles = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};

const labelStyles = {
  fontSize: "16px",
  fontWeight: 500,
};

const imageMainContainerStyles = {
  width: "80px",
  minWidth: "80px",
  height: "80px",
  border: "0.5px solid rgba(0,0,0,0.5)",
  // display: "flex",
  // justifyContent: "center",
  // alignItems: "center",
  marginLeft: "5px",
  marginRight: "5px",
};

const imageStyle = {
  width: "100%",
  height: "100%",
  resizeMode: "cover",
};

const multiImageMainContainerStyles = {
  backgroundColor: "rgba(0,0,0,0.1)",
  padding: "10px",
  overflowX: "auto",
  display: "flex",
};

const MultiImageQuestionInput = (props) => {
  const [images, setImages] = useState(null);

  useEffect(() => {
    const data = props.data;

    if (data.answers && Array.isArray(data.answers)) {
      const urls = data.answers.map((item) => item.answer);
      setImages(urls);
    }
  }, [props.src]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ ...props.style, ...loaderContainerStyles }}>
      <p style={labelStyles}>{props.data.question.text}</p>
      <div style={multiImageMainContainerStyles}>
        {images &&
          images.map((item) => (
            <div style={imageMainContainerStyles}>
              <Image allowExpand={true} alt="" style={imageStyle} src={item} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default MultiImageQuestionInput;
