import React from "react";
import useStyles from "./style.js";

const Button = (props) => {
  const { title, buttonStyle, onClick, theme = "primary" } = props;
  const classes = useStyles({ theme });

  return (
    <button
      type={props.type}
      style={buttonStyle}
      className={classes.button}
      onClick={onClick}
    >
      {title}
    </button>
  );
};

Button.defaultProps = {
  type: "button",
};

export default Button;
