import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
  },
  cameraIconContainer: {
    width: "25%",
    height: "25%",
    position: "absolute",
    bottom: 0,
    right: 0,
    background: theme.palette.white,
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  cameraIcon: {
    width: "60%",
    height: "60%",
  },
  labelContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
}));

export default useStyles;

export const styles = {
  avatar: {
    width: "100%",
    height: "100%",
    borderRadius: "100%",
  },
};
