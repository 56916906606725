import { PieChart } from "react-minimal-pie-chart";

const Chart = (props) => {
  return (
    <PieChart
      animate={true}
      label={(e) => {
        return parseInt(e.dataEntry.percentage) + "%";
      }}
      labelStyle={{ fontSize: 8, fill: "white" }}
      data={props.data}
    />
  );
};

Chart.defaultProps = {
  data: [],
};

export default Chart;
