import React, { useState, useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

import ChangePassword from "../screens/ChangePassword";
import MessageModal from "../components/MessageModal";
import SecureRoute from "./SecureRoute";
import Home from "../screens/Home";
import Login from "../screens/Login";
import CustomerDetails from "../screens/CustomerDetails";
import ForgotPassword from "../screens/ForgotPassword";
import OTPVerify from "../screens/OTPVerify";
import ResetPassword from "../screens/ResetPassword";
import EditProfile from "../screens/EditProfile";
import Questions from "../screens/Questions";
import AddQuestions from "../screens/AddQuestions";
import SubCategories from "../screens/SubCategories";
import AddSubCategory from "../screens/AddSubCatergories";
import CustomerAnswers from "../screens/CustomerAnswers";

const Router = (props) => {
  const [messageBox, setMessageBox] = useState({});

  useEffect(() => {
    if (props.authToken) {
      axios.defaults.headers.common["Authorization"] = props.authToken;
    }
  }, [props.authToken]);

  const showErrorDialog = (title, message) => {
    setMessageBox({
      isError: true,
      title: title,
      message: message,
      confirmText: "Okay",
    });
  };

  const hideErrorDialog = () => {
    setMessageBox({});
  };

  return (
    <>
      <BrowserRouter>
        <Switch>
          <SecureRoute
            showErrorDialog={showErrorDialog}
            allowWithoutChangingFirstPassword={false}
            exact={true}
            path="/dashboard"
            component={Home}
          />
          <Route exact={true} path="/" component={Login} />
          <Route exact={true} path="/verify-otp" component={OTPVerify} />
          <SecureRoute
            allowWithoutChangingFirstPassword={true}
            showErrorDialog={showErrorDialog}
            exact={true}
            path="/change-password"
            component={ChangePassword}
          />
          <SecureRoute
            showErrorDialog={showErrorDialog}
            allowWithoutChangingFirstPassword={false}
            exact={true}
            path="/profile"
            component={EditProfile}
          />
          <SecureRoute
            showErrorDialog={showErrorDialog}
            allowWithoutChangingFirstPassword={false}
            exact={true}
            path="/customer-answers/:id"
            component={CustomerAnswers}
          />
          <Route
            exact={true}
            path="/reset-password"
            component={ResetPassword}
          />
          <Route
            exact={true}
            path="/forgot-password"
            component={ForgotPassword}
          />
          <SecureRoute
            showErrorDialog={showErrorDialog}
            exact={true}
            allowWithoutChangingFirstPassword={false}
            path="/customers"
            component={CustomerDetails}
          />
          <SecureRoute
            showErrorDialog={showErrorDialog}
            allowWithoutChangingFirstPassword={false}
            exact={true}
            path="/questions"
            component={Questions}
          />
          <Route exact={true} path="/add-questions" component={AddQuestions} />
          <SecureRoute
            showErrorDialog={showErrorDialog}
            allowWithoutChangingFirstPassword={false}
            exact={true}
            path="/sub-categories"
            component={SubCategories}
          />
          <Route
            exact={true}
            path="/add-sub-categories"
            component={AddSubCategory}
          />
        </Switch>
      </BrowserRouter>
      <MessageModal data={messageBox} handleClose={hideErrorDialog} />
    </>
  );
};

const mapStateToProps = (store) => ({
  authToken: store.auth.authToken,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Router);
