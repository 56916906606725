import React from "react";
import useStyles from "./style.js";
import { InputBase } from "@material-ui/core";

import filterIcon from "../../assets/icons/filterIcon.png";
import FilterDropdown from "../FilterDropdown/index.js";
import selectedFilterIcon from "../../assets/icons/filterIconOrange.png";

const SearchBarWithFilter = (props) => {
  const {
    containerStyle,
    filters,
    onFilterChange,
    showFilterDropdown,
    toggleFilterDropdown,
    selectedFilter,
    onChangeText,
    value,
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.mainContainer}>
      <div className={classes.container} style={containerStyle}>
        <InputBase
          className={classes.inputField}
          fullWidth
          type="text"
          onChange={onChangeText}
          value={value}
          disabled={!selectedFilter}
          endAdornment={
            <div
              className={classes.endAdornment}
              onClick={toggleFilterDropdown}
            >
              {selectedFilter ? (
                <div className={classes.selectedFilterBox}>
                  <p className={classes.selectedFilter}>
                    Filter By {selectedFilter}
                  </p>
                  <img
                    alt="filter"
                    src={selectedFilterIcon}
                    className={classes.filterIcon}
                  />
                </div>
              ) : (
                <img
                  alt="filter"
                  src={filterIcon}
                  className={classes.filterIcon}
                />
              )}
            </div>
          }
        />
      </div>
      {showFilterDropdown && (
        <FilterDropdown
          filters={filters}
          onFilterChange={onFilterChange}
          selectedFilter={selectedFilter}
        />
      )}
    </div>
  );
};

export default SearchBarWithFilter;
