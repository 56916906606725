import useStyles from "./style";

const ColoredBox = (props) => {
  const classes = useStyles();

  const getStyles = () => {
    return {
      width: props.size,
      height: props.size,
      backgroundColor: props.color,
    };
  };

  return (
    <div className={classes.container}>
      <div style={getStyles()}></div>
      <span className={classes.label}>{props.children}</span>
    </div>
  );
};

ColoredBox.defaultProps = {
  size: "10px",
  color: "black",
};

export default ColoredBox;
