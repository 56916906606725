import React from "react";
import RightArrowButton from "../../../components/RightArrowButton";
import { useHistory } from "react-router-dom";

import ShieldIcon from "../../../assets/icons/shield.png";
import useStyles from "./style";

const AgentCard = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.container}>
      <div className={classes.leftContainer}>
        <div className={classes.chartHeadingContainer}>
          <span className={classes.chartHeading}>User</span>
          <span className={classes.chartSecondHeading}>Management</span>
        </div>
        <div className={classes.chartContainer}>
          <RightArrowButton onClick={() => history.push("customers")} />
        </div>
      </div>
      <div className={classes.backgroundIconContainer}>
        <img alt="shield" className={classes.shieldIcon} src={ShieldIcon} />
      </div>
    </div>
  );
};

export default AgentCard;
