import useStyles from "./style";

import checkedIcon from "../../assets/icons/checked.png";

const CheckBox = (props) => {
  const { label, checked = false, onChange } = props;
  const classes = useStyles();

  const handleChange = () => {
    if (props.disabled) {
      return;
    }

    if (onChange) {
      onChange(!checked);
    }
  };

  const getCheckedClasss = () => {
    let checkedClasses = `${classes.checked}`;

    if (props.disabled) {
      checkedClasses += ` ${classes.disabledChecked}`;
    }

    return checkedClasses;
  };

  const getLabelClasses = () => {
    let labelClasses = `${classes.label}`;

    if (props.disabled) {
      labelClasses += ` ${classes.disabledLabel}`;
    }

    return labelClasses;
  };

  return (
    <div className={classes.container} onClick={handleChange}>
      {checked ? (
        <div className={getCheckedClasss()}>
          <img alt="Checked" src={checkedIcon} className={classes.icon} />
        </div>
      ) : (
        <div className={classes.box} />
      )}
      <p className={getLabelClasses()}>{label}</p>
    </div>
  );
};

CheckBox.defaultProps = {
  disabled: false,
};

export default CheckBox;
