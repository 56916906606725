import useStyles from "./style";

const HorizontalSeperator = (props) => {
  const classes = useStyles();

  return (
    <div
      className={classes.container}
      style={{ background: props.color, height: props.height }}
    ></div>
  );
};

HorizontalSeperator.defaultProps = {
  color: "black",
  height: 1,
};

export default HorizontalSeperator;
