import ApexChart from "react-apexcharts";
import numeral from "numeral";

import constants from "../../../config/constants";
import colors from "../../../config/colors";

const Chart = (props) => {
  const OPTIONS = {
    chart: {
      id: "basic-bar",
      stacked: false,
      redrawOnParentResize: true,
      redrawOnWindowResize: true,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: false,
      },
    },
    colors: [
      colors.THEME_BLUE,
      colors.PRIMARY,
      colors.SECONDARY,
      colors.LIGHT_BLACKLIST,
      colors.YELLOW_GREEN,
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: 30,
        endingShape: "rounded",
        distributed: true,
      },
    },

    stroke: {
      show: true,
      width: 4,
      colors: ["transparent"],
    },
    xaxis: {
      floating: false,
      categories: props.xaxis,
      labels: {
        show: true,
        style: {
          colors: colors.DARKGREY,
          fontSize: "11px",
          fontFamily: "Roboto",
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: colors.DARKGREY,
          fontSize: "11px",
          fontFamily: "Roboto",
          fontWeight: 400,
        },
        offsetX: 0,
        offsetY: 0,
        rotate: 0,
        formatter: (value) => {
          return (
            constants.CURRENCY_SYMBOL.NIGERIAN +
            " " +
            numeral(value).format("0,00.00a")
          );
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
  };

  const SERIES = [
    {
      name: "series-1",
      data: props.data,
    },
  ];

  return (
    <ApexChart
      options={OPTIONS}
      series={SERIES}
      type="bar"
      width="100%"
      height="100%"
    />
  );
};

Chart.defaultProps = {
  xaxis: [],
  data: [],
};

export default Chart;
