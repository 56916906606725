import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: "100%",
    background: theme.palette.white,
    position: "absolute",
    border: "1px solid #D1D2D4",
    padding: theme.spacing(2),
    zIndex:2,
    backgroundColor: theme.palette.white,
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px"
  },
  title: {
    textAlign: "center",
    color: theme.palette.secondary.main
  },
  row: {
    display: "flex",
    // justifyContent: "space-around",
    marginTop: theme.spacing(2)
  },
  filterItem: {
    display: "flex",
    flex: 1,
    justifyContent: "space-around"
  },
  border: {
    border: `1px solid ${theme.palette.darkGrey}`
  },
  filterTextBox: {
    display:"flex",
    flexDirection: "column",
    cursor: "pointer"
  },
  filter: {
    color: "black",
    fontSize: "16px"
  },
  selectedBorder: {
    borderBottom: `2px solid ${theme.palette.orange}`,
    alignSelf: "center",
    width: "78%",
    marginTop: theme.spacing(1)
  },
  hidden: {
    visibility: "hidden"
  }
}));

export default useStyles;
