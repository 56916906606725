import React from "react";
import { Pagination } from "@material-ui/lab";
import useStyles from "./style";

import ListBox from "./ListBox";
import Spinner from "../Spinner";

const CustomerTable = (props) => {
  const {
    loading,
    userList,
    selectedTab,
    tableTitle,
    tableData,
    onPageChange,
    currentPage,
    totalPage,
  } = props;
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.tableHead}>
        {tableTitle.map((item) => (
          <p className={classes.dataSection}>{item}</p>
        ))}
      </div>
      <div
        className={
          !loading && !!userList.length ? classes.tableDataContainer : ""
        }
      >
        {loading && (
          <div className={classes.loaderBox}>
            <Spinner />
          </div>
        )}
        {!loading &&
          !!tableData.length &&
          tableData.map((item, index) => (
            <ListBox
              data={item}
              selectedTab={selectedTab}
              containerStyle={{
                marginBottom: tableData.length - 1 === index ? "25px" : 0,
              }}
            />
          ))}
        {!loading && !userList.length && (
          <p className={classes.noDataFound}>No Data Found</p>
        )}
      </div>
      {!loading && <div className={classes.paginationContainer}>
        <Pagination
          count={totalPage}
          shape="rounded"
          onChange={onPageChange}
          page={currentPage}
        />
      </div>}
    </div>
  );
};

export default CustomerTable;
