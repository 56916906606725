import { makeStyles } from "@material-ui/core/styles";
import colors from "../../config/colors";

const useStyles = makeStyles((theme) => ({
  loginContainer: {
    width: "100vw",
    height: "100vh",
    display: "flex!important",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  container: {
    backgroundColor: colors.WHITE,
    borderRadius: "20px",
    boxShadow:
      "0 14px 28px rgba(0,0,0,0.25), \r\n\t\t\t0 10px 10px rgba(0,0,0,0.22)",
    position: "relative",
    overflow: "hidden",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "coloumn",
  },
  form: {
    width: "100%",
  },
  containerLeft: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    backgroundColor: colors.WHITE,
    flex: 1,
    height: "auto",
  },
  leftContainerContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    maxWidth: 530,
  },
  soladLogoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(5),
    width: "100%",
    maxWidth: "300px",
    minWidth: "150px",
  },
  soladLogo: {
    width: "100%",
  },
  inputFieldContainer: {
    height: "auto",
    width: "100%",
    marginBottom: "1.5em",
  },
  label: {
    fontSize: "1em",
    fontWeight: "500",
  },
  containerLeftText3: {
    fontSize: "1.2em",
    fontWeight: "600",
    textDecoration: "underline",
    cursor: "pointer",
  },
  containerLeftButton: {
    marginTop: "2em",
    width: "60%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    postion: "relative",
  },
  SignInTextContainer: {
    position: "absolute",
    backgroundColor: colors.ORANGE,
    color: colors.WHITE,
    width: "20%",
    textAlign: "center",
    padding: "1em",
    border: "none",
    boxShadow: "none",
    fontSize: "0.9vw",
    fontWeight: "light",
    borderRadius: "10px",
  },
  containerRight: {
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    height: "100%",
    width: "32%",
    position: "relative",
    maxWidth: 400,
    padding: theme.spacing(1.3),
  },
  containerRightBackground: {
    height: "100%",
    width: "100%",
    position: "absolute",
  },
  signInBtnBox: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
  signInButton: {
    color: "#fff",
    width: "67%",
    backgroundColor: "#FF7D0D",
    border: "none",
    fontSize: "1.2em",
    boxShadow: "none",
    fontWeight: "light",
    borderRadius: "15px",
    padding: "7px 0 7px 0",
  },
  rightContainerContent: {
    display: "flex",
    zIndex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
  },
  sologanBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flex: 1,
  },
  sologanText: {
    fontSize: "2.4em",
    color: theme.palette.white,
    padding: theme.spacing(1, 0, 1, 0),
  },
  appName: {
    fontSize: "1.5em",
    color: theme.palette.lightGrey,
    fontWeight: "bold",
  },
  select: {
    backgroundColor: "red",
    height: 50
  },
}));

export default useStyles;
