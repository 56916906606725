import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";

import useStyles from "./style";
import MessageModal from "../../components/MessageModal";
import AppFrame from "../../components/AppFrame";
import AppHeader from "../../components/AppHeader";
import Loader from "../../components/Loader";
import api from "../../utils/api";
import OutlinedInput from "../../components/OutlinedInput";
import SelectInput from "../../components/SelectInput";
import Button from "../../components/Button";
import MultipleSelect from "../../components/multiSelector";
import DropdownOptionsInput from "../../components/DropdownOptionsInput";
import constants from "../../config/constants";

const AddQuestions = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [allCategory, setAllCategory] = useState([]);
  const [allSubCategory, setAllSubCategory] = useState([]);
  const [allApplianceTypes, setAllApplianceTypes] = useState([]);
  const [allElementTypes, setAllElementTypes] = useState([]);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const [values, setValues] = useState({
    text: null,
    // editable: true,
    usedForEnegyConsumption: null,
    dailyAVerageHours: null,
    watt: 0,
    elementType: null,
    applianceType: null,
    allowMultiple: null,
    elementPlaceHolder: null,
    min: null,
    max: null,
    shouldRestrictNumber: null,
    shouldRestrictLetter: null,
    shouldRestrictSymbols: null,
    sequence: null,
    mandatory: null,
    category: [],
    subCategory: [],
  });

  useEffect(() => {
    fetchQuestionDetails();
    if (props.location.sequence && !props.location.value) {
      setValues({
        sequence: props.location.sequence + 1,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchEditQuestionDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.value ? props.location.value.question._id : ""]);

  const fetchEditQuestionDetails = async () => {
    let categories = [];
    let subCategories = [];

    if (props.location.value) {
      props.location.value.categories.forEach((item) =>
        categories.push(item._id)
      );
      props.location.value.subCategories.forEach((item) =>
        subCategories.push(item._id)
      );
      const { question } = props.location.value;
      setValues({
        text: question.text,
        usedForEnegyConsumption: question.useForEnergyConsumption,
        dailyAVerageHours: question.dailyAverageHours,
        watt: question.watt,
        elementType: question.element.type._id,
        elementOptions: question.element.options,
        applianceType: question.element.applianceType,
        allowMultiple: question.element.allowMultiple,
        elementPlaceHolder: question.element.placeholder,
        shouldRestrictNumber: question.element.validation.shouldRestrictNumber,
        shouldRestrictLetter: question.element.validation.shouldRestrictLetter,
        shouldRestrictSymbols:
          question.element.validation.shouldRestrictSymbols,
        sequence: props.location.value.sequence,
        mandatory: props.location.value.mandatory,
        category: categories,
        subCategory: subCategories,
        min: question.element.validation.min,
        max: question.element.validation.max,
      });
    }
  };

  const fetchQuestionDetails = async () => {
    try {
      setLoading(true);

      const promises = [
        fetchAllElementTypes(),
        fetchAllApplianceTypes(),
        fetchAllCategory(),
        fetchAllSubCategory(),
      ];

      await Promise.all(promises);
    } catch (e) {
      setMessage({
        isError: true,
        title: "Error",
        message: e?.message,
      });
    }

    setLoading(false);
  };

  const fetchAllElementTypes = async () => {
    try {
      const elementTypes = await api.getElementTypes();

      return setAllElementTypes(elementTypes.data.elementTypes);
    } catch (e) {
      throw e;
    }
  };

  const fetchAllApplianceTypes = async () => {
    try {
      const applianceType = await api.getApplianceTypes();

      return setAllApplianceTypes(applianceType.data.applianceType);
    } catch (e) {
      throw e;
    }
  };

  const fetchAllCategory = async () => {
    try {
      const category = await api.getCategory();
      const filteredCategory = category.data.questionCategories.filter(
        (category) => category.editable === true
      );

      return setAllCategory(filteredCategory);
    } catch (e) {
      throw e;
    }
  };

  const fetchAllSubCategory = async () => {
    try {
      const subCategory = await api.getSubCategory();
      const filteredSubCategory = subCategory.data.questionSubCategories.filter(
        (subCategory) => subCategory.editable === true
      );

      return setAllSubCategory(filteredSubCategory);
    } catch (e) {
      throw e;
    }
  };

  const handleChange = (prop) => (event) => {
    const previousValuesState = { ...values };

    setValues({ ...previousValuesState, [prop]: event.target.value });
  };

  const handleSelectChange = (label, value) => {
    if (value === "true") {
      value = true;
    }
    if (value === "false") {
      value = false;
    }

    const previousValuesState = { ...values };

    setValues({ ...previousValuesState, [label]: value });
  };

  const handleCloseModal = () => {
    setMessage({});
  };

  const saveQuestion = async () => {
    try {
      let message;
      const validation = validate();

      if (validation) {
        throw new Error(validation);
      }

      setLoading(true);

      const payload = {
        text: values.text,
        editable: true,
        useForEnergyConsumption: values.usedForEnegyConsumption,
        dailyAverageHours: values.dailyAVerageHours,
        watt: values.watt,
        elementType: values.elementType,
        applianceType: values.applianceType,
        allowMultiple: values.allowMultiple,
        elementPlaceholder: values.elementPlaceHolder,
        min: values.min,
        max: values.max,
        shouldRestrictNumber: values.shouldRestrictNumber,
        shouldRestrictLetter: values.shouldRestrictLetter,
        shouldRestrictSymbols: values.shouldRestrictSymbols,
        sequence: values.sequence,
        mandatory: values.mandatory,
        categories: values.category,
        subCategories: values.subCategory,
        isActive: true,
        elementOptions: values.elementOptions,
      };

      if (!values.usedForEnegyConsumption) {
        delete payload.dailyAverageHours;
      }
      if (!values.min) {
        delete payload.min;
      }
      if (!values.max) {
        delete payload.max;
      }
      if (props.location.value) {
        payload._id = props.location.value.question._id;
        payload.element = {
          validation: {
            max: values.max,
            min: values.min,
            shouldRestrictNumber: values.shouldRestrictNumber,
            shouldRestrictLetter: values.shouldRestrictLetter,
            shouldRestrictSymbols: values.shouldRestrictSymbols,
          },
          options: values.elementOptions,
          type: values.elementType,
          allowMultiple: values.allowMultiple,
          placeholder: values.elementPlaceHolder,
          applianceType: values.applianceType,
        };
        message = "Question edited successfully";
        await api.editQuestion(payload);
      } else {
        await api.createQuestion(payload);
        message = "Question created successfully";
      }

      setMessage({
        isError: false,
        title: "Success",
        message: message,
        onConfirm: () => {
          history.push("/questions");
        },
      });
    } catch (e) {
      setMessage({
        isError: true,
        title: "Error",
        message: e?.message,
      });
    }

    setLoading(false);
  };

  const validate = () => {
    const errors = {};
    let errorMessage = "This field is required";

    if (!values.text) {
      errors.text = errorMessage;
    }
    if (values.usedForEnegyConsumption == null) {
      errors.usedForEnegyConsumption = errorMessage;
    }
    if (!values.category) {
      return "Category is required";
    }

    if (values.usedForEnegyConsumption && !values.dailyAVerageHours) {
      errors.dailyAVerageHours = errorMessage;
    }
    if (values.usedForEnegyConsumption && !values.watt) {
      errors.watt = errorMessage;
    }
    if (!values.elementType) {
      errors.elementType = errorMessage;
    }
    // if (!values.applianceType) {
    //   errors.applianceType = errorMessage;
    // }
    if (values.usedForEnegyConsumption && !values.applianceType) {
      errors.applianceType = errorMessage;
    }
    // if (!values.allowMultiple) {
    //   errors.allowMultiple = errorMessage;
    // }
    if (!values.elementPlaceHolder) {
      errors.elementPlaceHolder = errorMessage;
    }
    // if (!values.min) {
    //   errors.min = errorMessage;
    // }
    // if (!values.max) {
    //   errors.max = errorMessage;
    // }
    // if (values.shouldRestrictLetter == null) {
    //   errors.shouldRestrictLetter = errorMessage;
    // }
    // if (values.shouldRestrictNumber == null) {
    //   errors.shouldRestrictNumber = errorMessage;
    // }
    // if (values.shouldRestrictSymbols == null) {
    //   errors.shouldRestrictSymbols = errorMessage;
    // }
    if (!values.sequence) {
      errors.sequence = errorMessage;
    }
    if (values.mandatory == null) {
      errors.mandatory = errorMessage;
    }
    setErrors(errors);

    const keys = Object.keys(errors);

    if (!values.category) {
      return "Category is required";
    }
    if (values.category && values.category.length < 1) {
      return "Sub Category is required";
    }
    if (!values.subCategory) {
      return "Sub Category is required";
    }
    if (values.subCategory && values.subCategory.length < 1) {
      return "Sub Category is required";
    }

    if (keys.length) {
      return "Please fix validation errors";
    }
  };

  return (
    <div className={classes.mainContainer}>
      {loading && <Loader />}
      <MessageModal data={message} handleClose={handleCloseModal} />
      <div className={classes.container}>
        <AppFrame>
          <div className={classes.contentContainer}>
            <AppHeader />
            <div className={classes.headerContainer}>
              <div className={classes.headingContainer}>
                <h1 className={classes.heading}>
                  {props.location.value ? "Edit Question" : "Add Question"}
                </h1>
              </div>
              <div className={classes.horizontalDividerContainer}>
                <hr className={classes.horizontalDivider} />
              </div>
            </div>
            <div className={classes.inputContenttContainer}>
              <div className={classes.inputHeadingContainer}>
                <h1 className={classes.inputHeading}>Enter details</h1>
              </div>
              <div className={classes.inputFieldsContainer}>
                <span className={classes.label}>Text</span>
                <OutlinedInput
                  fullWidth={true}
                  onChange={handleChange("text")}
                  value={values.text}
                  errorMessage={errors.text}
                />
                <div className={classes.inlineInputs}>
                  <div
                    className={`${classes.inlineInputLables} ${classes.inlinePaddingLeft} ${classes.inlinePaddingTop} ${classes.inlinePaddingBottom}`}
                  >
                    <span className={classes.label}>Category</span>
                    <MultipleSelect
                      options={allCategory}
                      onChange={(e) => handleSelectChange("category", e)}
                      errorMessage={errors.category}
                      value={values.category}
                    />
                  </div>
                  <div
                    className={`${classes.inlineInputLables} ${classes.inlinePaddingTop} ${classes.inlinePaddingBottom}`}
                  >
                    <span className={classes.label}>Sub-Category</span>
                    <MultipleSelect
                      options={allSubCategory}
                      onChange={(e) => handleSelectChange("subCategory", e)}
                      errorMessage={errors.subCategory}
                      value={values.subCategory}
                    />
                  </div>
                </div>
                <span className={`${classes.label}`}>Element Placeholder</span>
                <OutlinedInput
                  fullWidth={true}
                  onChange={handleChange("elementPlaceHolder")}
                  value={values.elementPlaceHolder}
                  errorMessage={errors.elementPlaceHolder}
                />
                <div className={classes.inlineInputs}>
                  <div
                    className={`${classes.inlineInputLables} ${classes.inlinePaddingLeft} ${classes.inlinePaddingTop}`}
                  >
                    <span className={classes.label}>
                      Used For Energy Consumption
                    </span>
                    <SelectInput
                      options={constants.YES_NO_OPTIONS}
                      value={values.usedForEnegyConsumption}
                      onChange={(e) =>
                        handleSelectChange("usedForEnegyConsumption", e)
                      }
                      fullWidth={true}
                      errorMessage={errors.usedForEnegyConsumption}
                    />
                  </div>
                  <div
                    className={`${classes.inlineInputLables} ${classes.inlinePaddingLeft} ${classes.inlinePaddingTop}`}
                  >
                    <span className={classes.label}>Daily Average Hours</span>
                    <OutlinedInput
                      fullWidth={true}
                      onChange={handleChange("dailyAVerageHours")}
                      value={values.dailyAVerageHours}
                      disabled={!values.usedForEnegyConsumption}
                      errorMessage={errors.dailyAVerageHours}
                    />
                  </div>
                  <div
                    className={`${classes.inlineInputLables} ${classes.inlinePaddingTop}`}
                  >
                    <span className={classes.label}>Watt</span>
                    <OutlinedInput
                      fullWidth={true}
                      onChange={handleChange("watt")}
                      value={values.watt}
                      disabled={!values.usedForEnegyConsumption}
                      errorMessage={errors.watt}
                    />
                  </div>
                </div>
                <div className={classes.inlineInputs}>
                  <div
                    className={`${classes.inlineInputLables} ${classes.inlinePaddingLeft} ${classes.inlinePaddingTop}`}
                  >
                    <span className={classes.label}>Element Type</span>
                    <SelectInput
                      options={allElementTypes}
                      value={values.elementType}
                      onChange={(e) => handleSelectChange("elementType", e)}
                      fullWidth={true}
                      errorMessage={errors.elementType}
                    />
                  </div>
                  <div
                    className={`${classes.inlineInputLables} ${classes.inlinePaddingLeft} ${classes.inlinePaddingTop}`}
                  >
                    <span className={classes.label}>Appliance Type</span>
                    <SelectInput
                      options={allApplianceTypes}
                      value={values.applianceType}
                      onChange={(e) => handleSelectChange("applianceType", e)}
                      fullWidth={true}
                      errorMessage={errors.applianceType}
                      disabled={!values.usedForEnegyConsumption}
                    />
                  </div>
                  <div
                    className={`${classes.inlineInputLables} ${classes.inlinePaddingTop}`}
                  >
                    <span className={classes.label}>Allow Multiple</span>
                    <SelectInput
                      options={constants.YES_NO_OPTIONS}
                      value={values.allowMultiple}
                      onChange={(e) => handleSelectChange("allowMultiple", e)}
                      fullWidth={true}
                      errorMessage={errors.allowMultiple}
                    />
                  </div>
                </div>
                <div className={`${classes.inputContainer}`}>
                  <DropdownOptionsInput
                    options={values.elementOptions}
                    onChange={(e) => handleSelectChange("elementOptions", e)}
                    label="Options"
                  />
                </div>
                <div className={classes.inlineInputs}>
                  <div
                    className={`${classes.inlineInputLables} ${classes.inlinePaddingLeft} ${classes.inlinePaddingTop}`}
                  >
                    <span className={classes.label}>Min</span>
                    <OutlinedInput
                      fullWidth={true}
                      onChange={handleChange("min")}
                      value={values.min}
                      errorMessage={errors.min}
                    />
                  </div>
                  <div
                    className={`${classes.inlineInputLables} ${classes.inlinePaddingTop}`}
                  >
                    <span className={classes.label}>Max</span>
                    <OutlinedInput
                      fullWidth={true}
                      onChange={handleChange("max")}
                      value={values.max}
                      errorMessage={errors.max}
                    />
                  </div>
                </div>
                <div className={classes.inlineInputs}>
                  <div
                    className={`${classes.inlineInputLables} ${classes.inlinePaddingLeft} ${classes.inlinePaddingTop}`}
                  >
                    <span className={classes.label}>
                      Should Restrict Numbers
                    </span>
                    <SelectInput
                      options={constants.YES_NO_OPTIONS}
                      value={values.shouldRestrictNumber}
                      onChange={(e) =>
                        handleSelectChange("shouldRestrictNumber", e)
                      }
                      fullWidth={true}
                      errorMessage={errors.shouldRestrictNumber}
                    />
                  </div>
                  <div
                    className={`${classes.inlineInputLables} ${classes.inlinePaddingLeft} ${classes.inlinePaddingTop}`}
                  >
                    <span className={classes.label}>
                      Should Restrict Letters
                    </span>
                    <SelectInput
                      options={constants.YES_NO_OPTIONS}
                      value={values.shouldRestrictLetter}
                      onChange={(e) =>
                        handleSelectChange("shouldRestrictLetter", e)
                      }
                      fullWidth={true}
                      errorMessage={errors.shouldRestrictLetter}
                    />
                  </div>
                  <div
                    className={`${classes.inlineInputLables} ${classes.inlinePaddingTop}`}
                  >
                    <span className={classes.label}>
                      Should Restrict Symbols
                    </span>
                    <SelectInput
                      options={constants.YES_NO_OPTIONS}
                      value={values.shouldRestrictSymbols}
                      onChange={(e) =>
                        handleSelectChange("shouldRestrictSymbols", e)
                      }
                      fullWidth={true}
                      errorMessage={errors.shouldRestrictSymbols}
                    />
                  </div>
                </div>

                <div className={classes.inlineInputs}>
                  <div
                    className={`${classes.inlineInputLables} ${classes.inlinePaddingLeft} ${classes.inlinePaddingTop}`}
                  >
                    <span className={classes.label}>Sequence</span>
                    <OutlinedInput
                      fullWidth={true}
                      onChange={handleChange("sequence")}
                      value={values.sequence}
                      errorMessage={errors.sequence}
                    />
                  </div>
                  <div
                    className={`${classes.inlineInputLables} ${classes.inlinePaddingTop}`}
                  >
                    <span className={classes.label}>Mandatory</span>
                    <SelectInput
                      options={constants.YES_NO_OPTIONS}
                      value={values.mandatory}
                      onChange={(e) => handleSelectChange("mandatory", e)}
                      fullWidth={true}
                      errorMessage={errors.mandatory}
                    />
                  </div>
                </div>

                <div className={classes.buttonContainer}>
                  <div className={classes.button}>
                    <Button
                      type="button"
                      title="Save"
                      theme="primary"
                      onClick={saveQuestion}
                    />
                  </div>
                </div>
              </div>

              {/* <div className={classes.addButtonContainer}>
                <Button
                  type="button"
                  title="Add More"
                  buttonStyle={{
                    borderTopLeftRadius: "20px",
                    borderBottomLeftRadius: "20px",
                    borderBottomRightRadius: 0,
                    borderTopRightRadius: 0,
                    backgroundColor: theme.palette.darkGrey,
                    borderColor: theme.palette.darkGrey,
                  }}
                  theme="primary"
                  onClick={addNewQuestion}
                />
              </div> */}
            </div>
          </div>
        </AppFrame>
      </div>
    </div>
  );
};

export default AddQuestions;
