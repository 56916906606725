const colors = {
  PRIMARY: "#FF7D0D",
  SECONDARY: "#26c6bb",
  BACKGROUND: "#F0F4F3",
  WHITE: "#fff",
  ORANGE: "#FF8C13",
  GREY: "#e1e1e1",
  DARKGREY: "#828080",
  LIGHT_GREY: "#D1D2D4",
  LIGHT_ORANGE: "#FFA51D",
  MEDIUM_GREY: "#9e9fa0",
  BLACK: "black",
  THEME_BLUE: "#2573c2",
  SUCCESS: "#10d48c",
  RED: "#df2121",
  LIGHT_BLACKLIST: "#5E7080",
  YELLOW_GREEN: "#ACC213",
  LIGHT_BLACK: "#262626",
  LIGHT_AQUA: "#F9F9F9",
  ICON_BLUE: "#347BD1"
};

export default colors;
