import { Typography } from "@material-ui/core";
import { connect } from "react-redux";

import SoladLogo from "../../assets/icons/solad-logo.png";
import Avatar from "../Avatar";
import useStyles from "./style";

const AppHeader = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Avatar source={props.user.profile.picture} />
      <div className={classes.greetContainer}>
        {/* <Typography variant="h6">Hi Admin,</Typography> */}
        <Typography variant="h4" className={classes.welcomeHeading}>
          Welcome {props.user.profile.firstName}
        </Typography>
      </div>
      <img alt="solad-logo" className={classes.soladLogo} src={SoladLogo} />
    </div>
  );
};

const mapStateToProps = (store) => ({
  user: store.auth.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
