import React from "react";
import useStyles from "./style.js";

import loaderIcon from "../../assets/icons/loader.png";

const Loader = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <img alt="loader" src={loaderIcon} className={classes.loader} />
    </div>
  );
};

export default Loader;
