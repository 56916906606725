import React from "react";
import useStyles from "./style";

const ListBox = (props) => {
  const { data, containerStyle } = props;
  const classes = useStyles();

  return (
    <div className={classes.content} style={containerStyle}>
      {data?.map((item) =>
        typeof item === "string" ? (
          <div className={classes.dataSection}><p>{item}</p></div>
        ) : (
          item
        )
      )}
    </div>
  );
};

export default ListBox;
