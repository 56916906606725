import React from "react";
import { useHistory } from "react-router-dom";

import useStyles from "./style";
import LeftArrowBlack from "../../assets/icons/left-arrow-black.png";

const BackButton = (props) => {
  const history = useHistory();
  const classes = useStyles();

  const handleClick = () => {
    if (props.onClick) {
      return props.onClick();
    }

    history.goBack();
  };

  return (
    <div onClick={handleClick} className={classes.container}>
      <img
        className={classes.leftArrow}
        src={LeftArrowBlack}
        alt="left-arrow"
      />
      <span className={classes.label}>Back</span>
    </div>
  );
};

export default BackButton;
