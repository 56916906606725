import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.background,
    height: "100%",
  },
  container: {
    width: "100%",
    height: "100%",
    padding: "30px 30px",
  },
  contentContainer: {
    padding: theme.spacing(3),
    position: "relative",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
  },
  contentInnerContainer: {
    marginTop: theme.spacing(3),
  },
  sectionContainer: {
    marginBottom: theme.spacing(2),
  },
  sectionHeading: {
    // backgroundColor: theme.palette.lightOrange,
    padding: theme.spacing(1),
    // borderRadius: "5px",
    fontWeight: 500,
    fontSize: "1.1em",
    marginBottom: theme.spacing(2),
    color: "black",
    borderBottom: "1px solid black",
  },
  answerRow: {
    display: "flex",
    flexDirection: "row",
  },
  backButtonContainer: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  excelButton: {
    background: "transparent",
    border: "none",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    "& span": {
      color: "black",
      fontSize: "1.4em",
      marginRight: theme.spacing(1)
    },
  },
  excelIcon: {
    width: "30px",
    height: "30px",
  },
}));

export default useStyles;
