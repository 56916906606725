import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    overflowX: "hidden"
  },
  tableHeader: {
    border: 0,
  },
  tableHead: {
    display: "flex",
    justifyContent: "space-around",
    textAlign: "center",
    color: theme.palette.mediumGrey,
    fontWeight: "bold",
    height: "45px",
  },
  pictureSection: {
    width: "80px",
  },
  dataSection: {
    flex: 1,
    fontSize: "1.2em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tableDataContainer: {
    // paddingBottom: theme.spacing(3),
    overflow: "auto",
    overflowX: "hidden",
    height: "100%",
    flex: 1,
  },
  loaderBox: {
    display: "flex",
    justifyContent: "center",
    height: "40px",
    alignItems: "center",
  },
  noDataFound: {
    height: "52px",
    display: "flex",
    flex: "1",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1.2em",
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "center"
  }
}));

export default useStyles;
