import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";

import Button from "../Button";
import DropdownOptionList from "./DropdownOptionList";
import AddIcon from "../../assets/icons/add.png";
import useStyles from "./style";

const DropdownOptionsInput = (props) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  const handleInputClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOptions(props.options);
  };

  const handleSubmit = () => {
    setOpen(false);

    if (props.onChange) {
      props.onChange(options);
    }
  };

  const handleCountryCodeSubmit = (data) => {
    const newOptions = [...options];
    const payload = {
      label: data.label,
      value: data.value,
    };

    newOptions.push(payload);
    setOptions([...newOptions]);
  };

  const handleDelete = (index) => {
    const items = [...options];
    items.splice(index, 1);
    setOptions(items);
  };

  return (
    <>
      <div onClick={handleInputClick} className={classes.mainContainer}>
        <p className={classes.label}>{props.label}</p>
        <div className={classes.inputContainer}>
          <span style={{ flex: 1 }}>
            {options.length} options added
          </span>
          <img className={classes.addIcon} src={AddIcon} alt="add" />
        </div>
      </div>
      <Dialog fullWidth={true} open={open}>
        {open && (
          <>
            <div className={classes.dialogBodyContainer}>
              <DropdownOptionList
                onSubmit={handleCountryCodeSubmit}
                editEnabled={true}
              />
              <div className={classes.countryListContainer}>
                {options.map((item, i) => (
                  <DropdownOptionList
                    allData={options}
                    onDelete={() => handleDelete(i)}
                    key={Math.random().toString()}
                    data={item}
                    editEnabled={false}
                  />
                ))}
              </div>
            </div>
            <div className={classes.dialogFooterContainer}>
              <Button
                type="submit"
                title="Save"
                onClick={handleSubmit}
                theme="primary"
                buttonStyle={{
                  width: "100px",
                  height: "35px",
                  fontSize: "14px",
                }}
              />
              <Button
                type="submit"
                title="Cancel"
                onClick={handleClose}
                theme="primary"
                buttonStyle={{
                  width: "100px",
                  height: "35px",
                  fontSize: "14px",
                  backgroundColor: "transparent",
                  color: "black",
                  border: "none",
                }}
              />
            </div>
          </>
        )}
      </Dialog>
    </>
  );
};

DropdownOptionsInput.defaultProps = {
  options: [],
};

export default DropdownOptionsInput;
