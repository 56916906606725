import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.background,
    height: "100%",
  },
  container: {
    width: "100%",
    height: "100%",
    padding: "30px 30px",
  },
  contentContainer: {
    padding: theme.spacing(3),
    position: "relative",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  heading: {
    width: "100%",
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
    flexDirection: "row",
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
    color: theme.palette.orange,
    fontSize: "1.2em",
    letterSpacing: ".5em",
  },
  tableContainer: {
    marginTop: theme.spacing(2),
    height: "80%",
    overflow: "auto",
  },
  addIcon: {
    cursor: "pointer"
  }
}));

export default useStyles;
