import React, { useState, useEffect } from "react";
import { Modal, Fade, Box } from "@material-ui/core";

import Button from "../Button";
import CrossIcon from "../../assets/icons/circleCross.png";
import useStyles from "./style";
import expandIcon from "../../assets/icons/expand.png";
import loaderIcon from "../../assets/icons/loader.png";
import api from "../../utils/api";
import axiosUtils from "../../utils/axios";

const loaderStyles = {
  width: "30%",
  height: "30%",
  animation: "spin 1s infinite linear",
};

const loaderContainerStyles = {
  backgroundColor: "rgba(0,0,0,0.1)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const imageMainStyles = {
  width: "100%",
  height: "100%",
  position: "relative",
};

const expandIconStyles = {
  backgroundColor: "rgba(0,0,0,0.5)",

  position: "absolute",
  bottom: "5%",
  right: "5%",
  width: "20%",
  height: "20%",
};

const Image = (props) => {
  const [loading, setLoading] = useState(false);
  const [baseURL, setBaseURL] = useState(null);
  const [openFullscreen, setOpenFullscreen] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (props.src) {
      if (props.src.startsWith("http")) {
        downloadImageUsingURL();
      } else if (
        props.src.startsWith("data:") &&
        props.src.includes("base64")
      ) {
        setBaseURL(props.src);
      } else {
        downloadImageUsingFilename();
      }
    }
  }, [props.src]); // eslint-disable-line react-hooks/exhaustive-deps

  const downloadImageUsingURL = async () => {
    try {
      setLoading(true);
      const data = await axiosUtils.downloadImage(props.src);

      setBaseURL(data);
    } catch (e) {
      console.log("e =>", e);
    }
    setLoading(false);
  };

  const downloadImageUsingFilename = async () => {
    try {
      setLoading(true);
      const data = await api.downloadImage({
        filename: props.src,
      });

      setBaseURL(data.data.image);
    } catch (e) {
      console.log("e =>", e);
    }
    setLoading(false);
  };

  const expandImage = () => {
    // const a = document.createElement("a"); //Create <a>
    // a.href = baseURL; //Image Base64 Goes here
    // a.download = "Image.png"; //File name Here
    // a.click(); //Downloaded file

    // let w = window.open("about:blank");
    // let image = document.createElement("img");
    // image.src = baseURL;
    // setTimeout(function () {
    //   w.document.write(image.outerHTML);
    // }, 0);

    setOpenFullscreen(true);
  };

  const handleModalClose = () => {
    setOpenFullscreen(false);
  };

  const handleDownload = () => {
    const a = document.createElement("a"); //Create <a>
    a.href = baseURL; //Image Base64 Goes here
    a.download = "Image.png"; //File name Here
    a.click(); //Downloaded file
  };

  if (!loading) {
    return (
      <div style={imageMainStyles}>
        <img alt="" style={props.style} src={baseURL} />
        {props.allowExpand && (
          <img
            alt=""
            onClick={expandImage}
            style={expandIconStyles}
            src={expandIcon}
          />
        )}
        <Modal
          open={openFullscreen}
          closeAfterTransition
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade timeout={{ appear: 0, enter: 500 }} in={openFullscreen}>
            <div className={classes.modalContainer}>
              <div className={classes.modalHeaderContainer}>
                <Button
                  onClick={handleDownload}
                  buttonStyle={{ width: "150px", marginRight: "10px" }}
                  title="Download"
                  theme="primary"
                />
                <img
                  alt="cross"
                  onClick={handleModalClose}
                  className={classes.modalCrossIcon}
                  src={CrossIcon}
                />
              </div>
              <div className={classes.modalContentContainer}>
                <Box className={classes.boxContainer} boxShadow={4}>
                  <img alt="" className={classes.image} src={baseURL} />
                </Box>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  }

  return (
    <div style={{ ...props.style, ...loaderContainerStyles }}>
      {loading && <img alt="loader" style={loaderStyles} src={loaderIcon} />}
    </div>
  );
};

export default Image;
