import React, { useReducer, useEffect } from "react";

import AgentCard from "./AgentCard";
import CashReceivedCard from "./CashReceivedCard";
import CashReceivableCard from "./CashReceivableCard";
import CustomerRetentionBarChart from "../../components/CustomerRetentionBarChart";
import CashReceivableBarChart from "../../components/CashReceivableBarChart";
import CustomerStatusPieChart from "../../components/CustomerStatusPieChart";
import AppFrame from "../../components/AppFrame";
import useStyles from "./style";
import AppHeader from "../../components/AppHeader";
import api from "../../utils/api";
import globalHelpers from "../../utils/globalHelpers";

const initialState = {
  customerStatus: {
    loading: false,
    data: {},
    error: "",
  },
  cashReceivableByClientType: {
    loading: false,
    data: [],
    error: "",
  },
  cashReceived: {
    loading: false,
    data: 0,
    error: "",
  },
  cashPending: {
    loading: false,
    data: 0,
    error: "",
  },
  customerRetention: {
    loading: false,
    data: [],
    error: "",
    selectedYear: "",
    selectedMonth: "all",
    allYears: [],
  },
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_CUSTOMER_STATUS":
      return {
        ...state,
        customerStatus: { ...state.customerStatus, ...action.payload },
      };
    case "SET_CASH_RECEIVABLE_BY_CLIENT_TYPE":
      return {
        ...state,
        cashReceivableByClientType: {
          ...state.cashReceivableByClientType,
          ...action.payload,
        },
      };
    case "SET_CASH_RECEIVED":
      return {
        ...state,
        cashReceived: {
          ...state.cashReceived,
          ...action.payload,
        },
      };
    case "SET_CASH_PENDING":
      return {
        ...state,
        cashPending: {
          ...state.cashPending,
          ...action.payload,
        },
      };
    case "SET_CUSTOMER_RETENTION":
      return {
        ...state,
        customerRetention: {
          ...state.customerRetention,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}

const Home = () => {
  const classes = useStyles();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    fetchCustomerStatus();
    fetchCashReceivableByClientType();
    fetchCashPending();
    fetchCashReceived();
    fetchStartingYear();
  }, []); // eslint-disable-line

  const fetchStartingYear = async () => {
    try {
      dispatch({ type: "SET_CUSTOMER_RETENTION", payload: { loading: true } });

      const response = await api.getStartingYearOfLeads();
      const startingYear = response.data.year;
      const allYears = globalHelpers.generateYearRange(startingYear);

      const newRetentionData = {
        selectedYear: allYears[allYears.length - 1].value,
        allYears,
      };

      dispatch({
        type: "SET_CUSTOMER_RETENTION",
        payload: newRetentionData,
      });

      fetchCustomerRetention(newRetentionData);
    } catch (e) {
      dispatch({
        type: "SET_CUSTOMER_RETENTION",
        payload: { loading: false, error: e.message, data: {} },
      });
    }
  };

  const fetchCustomerStatus = async () => {
    try {
      dispatch({ type: "SET_CUSTOMER_STATUS", payload: { loading: true } });

      const response = await api.fetchCustomerStatusOverview();

      dispatch({
        type: "SET_CUSTOMER_STATUS",
        payload: { loading: false, error: "", data: response.data.leads },
      });
    } catch (e) {
      dispatch({
        type: "SET_CUSTOMER_STATUS",
        payload: { loading: false, error: e.message, data: {} },
      });
    }
  };

  const fetchCustomerRetention = async (newRetentionData = {}) => {
    try {
      const mixedRetentionData = {
        ...state.customerRetention,
        ...newRetentionData,
      };

      dispatch({ type: "SET_CUSTOMER_RETENTION", payload: { loading: true } });

      const payload = {
        type: "year",
        year: mixedRetentionData.selectedYear,
      };

      if (mixedRetentionData.selectedMonth !== "all") {
        payload.month = mixedRetentionData.selectedMonth;
        payload.type = "month";
      }

      const response = await api.fetchCustomerRetention(payload);

      dispatch({
        type: "SET_CUSTOMER_RETENTION",
        payload: { loading: false, error: "", data: response.data.leads },
      });
    } catch (e) {
      dispatch({
        type: "SET_CUSTOMER_RETENTION",
        payload: { loading: false, error: e.message, data: {} },
      });
    }
  };

  const fetchCashReceived = async () => {
    try {
      dispatch({ type: "SET_CASH_RECEIVED", payload: { loading: true } });

      const response = await api.fetchCashReceived();

      dispatch({
        type: "SET_CASH_RECEIVED",
        payload: { loading: false, error: "", data: response.data.amount },
      });
    } catch (e) {
      dispatch({
        type: "SET_CASH_RECEIVED",
        payload: { loading: false, error: e.message, data: 0 },
      });
    }
  };

  const fetchCashPending = async () => {
    try {
      dispatch({ type: "SET_CASH_PENDING", payload: { loading: true } });

      const response = await api.fetchCashReceived();

      dispatch({
        type: "SET_CASH_PENDING",
        payload: { loading: false, error: "", data: response.data.amount },
      });
    } catch (e) {
      dispatch({
        type: "SET_CASH_PENDING",
        payload: { loading: false, error: e.message, data: 0 },
      });
    }
  };

  const fetchCashReceivableByClientType = async () => {
    try {
      dispatch({
        type: "SET_CASH_RECEIVABLE_BY_CLIENT_TYPE",
        payload: { loading: true },
      });

      const response = await api.fetchCashReceivable();

      dispatch({
        type: "SET_CASH_RECEIVABLE_BY_CLIENT_TYPE",
        payload: { loading: false, error: "", data: response.data.leads },
      });
    } catch (e) {
      dispatch({
        type: "SET_CASH_RECEIVABLE_BY_CLIENT_TYPE",
        payload: { loading: false, error: e.message, data: {} },
      });
    }
  };

  const handleSelectMonth = (newMonth) => {
    const newRetentionData = { selectedMonth: newMonth };
    dispatch({
      type: "SET_CUSTOMER_RETENTION",
      payload: newRetentionData,
    });
    fetchCustomerRetention(newRetentionData);
  };

  const handleSelectYear = (newYear) => {
    const newRetentionData = { selectedYear: newYear };
    dispatch({
      type: "SET_CUSTOMER_RETENTION",
      payload: newRetentionData,
    });
    fetchCustomerRetention(newRetentionData);
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.container}>
        <AppFrame name="dashboard">
          <div className={classes.frameContainer}>
            <div className={classes.contentContainer}>
              <AppHeader />
              <div className={classes.dataMainContainer}>
                <div className={classes.dataLeftContainer}>
                  <CustomerRetentionBarChart
                    data={state.customerRetention.data}
                    allYears={state.customerRetention.allYears}
                    loading={state.customerRetention.loading}
                    error={state.customerRetention.error}
                    onYearChange={handleSelectYear}
                    onMonthChange={handleSelectMonth}
                    selectedYear={state.customerRetention.selectedYear}
                    selectedMonth={state.customerRetention.selectedMonth}
                  />
                  <div className={classes.belowBarChartContainer}>
                    <div className={classes.pieChartContainer}>
                      <CustomerStatusPieChart
                        data={state.customerStatus.data}
                        error={state.customerStatus.error}
                        loading={state.customerStatus.loading}
                      />
                    </div>
                    <div className={classes.secondBarChartContainer}>
                      <CashReceivableBarChart
                        data={state.cashReceivableByClientType.data}
                        error={state.cashReceivableByClientType.error}
                        loading={state.cashReceivableByClientType.loading}
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.dataRightContainer}>
                  <AgentCard />
                  <div className={classes.belowDataRightContainer}>
                    <div className={classes.cashReceivedContainer}>
                      <CashReceivedCard
                        data={state.cashReceived.data}
                        loading={state.cashReceived.loading}
                        error={state.cashReceived.error}
                      />
                    </div>
                    <div className={classes.cashReceivableContainer}>
                      <CashReceivableCard
                        data={state.cashPending.data}
                        loading={state.cashPending.loading}
                        error={state.cashPending.error}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AppFrame>
      </div>
    </div>
  );
};

export default Home;
