import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import CategoryIcon from "@material-ui/icons/Category";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import ReactTooltip from "react-tooltip";

import authActions from "../../redux/auth/action";
import DashboardOrangeIcon from "../../assets/icons/dashboard-orange.png";
import SettingWhiteIcon from "../../assets/icons/settings.png";
import ChangePasswordWhiteIcon from "../../assets/icons/change-password-white.png";
import logoutWhiteIcon from "../../assets/icons/logout.png";
import useStyles from "./style";

const AppFrame = (props) => {
  const history = useHistory();
  const classes = useStyles();

  const navigateToDashboard = () => {
    history.push("/dashboard");
  };

  const navigateToChangePassword = () => {
    history.push("/change-password");
  };

  const navigateToQuestions = () => {
    history.push("/questions");
  };

  const navigateToSubCategory = () => {
    history.push("/sub-categories");
  };

  const handleLogout = () => {
    props.setUser({ user: null });
    history.replace("/");
  };

  const renderName = () => {
    const names = props.name.split(" ");

    return names.map((word, wordIndex) => {
      return (
        <div
          key={wordIndex}
          className={classes.wordContainer}
          style={getWordContainerStyles(wordIndex, names.length)}
        >
          {word.split("").map((item, i) => (
            <span className={classes.nameLetter} key={i}>
              {item.toUpperCase()}
            </span>
          ))}
        </div>
      );
    });
  };

  const getWordContainerStyles = (wordIndex, totalLength) => {
    if (wordIndex === 0) {
      return { marginTop: 0 };
    }

    if (totalLength - 1 === wordIndex) {
      return { marginBottom: 0 };
    }

    return {};
  };

  const navigateToEditProfile = () => {
    history.push("/profile");
  };

  return (
    <div className={`${classes.container} box-shadow`}>
      <div className={classes.leftContainer}>
        <div className={classes.dashboardIconContainer}>
          <img
            onClick={navigateToDashboard}
            className={classes.dashboardIcon}
            alt="dashboard-orange"
            src={DashboardOrangeIcon}
          />
        </div>
        <div className={classes.nameContainer}>{renderName()}</div>
        <div
          className={`${classes.iconContainer} ${classes.firstIconContainer}`}
        >
          <QuestionAnswerIcon
            style={{ fontSize: 30, color: "white" }}
            className={classes.icon}
            onClick={navigateToQuestions}
          />
        </div>

        <div
          className={`${classes.iconContainer} ${classes.firstIconContainer}`}
        >
          <CategoryIcon
            style={{ fontSize: 30, color: "white" }}
            className={classes.icon}
            onClick={navigateToSubCategory}
          />
        </div>

        <div
          className={`${classes.iconContainer} ${classes.firstIconContainer}`}
        >
          <img
            onClick={navigateToEditProfile}
            src={SettingWhiteIcon}
            alt="setting"
            className={classes.icon}
          />
        </div>
        <div
          className={`${classes.iconContainer} ${classes.firstIconContainer}`}
        >
          <img
            onClick={navigateToChangePassword}
            src={ChangePasswordWhiteIcon}
            alt="change password"
            className={classes.icon}
          />
        </div>
        <div className={classes.iconContainer}>
          <img
            onClick={handleLogout}
            src={logoutWhiteIcon}
            alt="logout"
            data-for="logout"
            data-tip="Logout"
            className={classes.icon}
          />
          <ReactTooltip id="logout" place="top" type="dark" effect="float" />
        </div>
      </div>
      <div className={classes.rightContainer}>{props.children}</div>
    </div>
  );
};

AppFrame.defaultProps = {
  name: "",
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  setUser: authActions.setUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppFrame);
