import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    height: "35px",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  inputContainer: {
    flex: 1,
  },
  input: {
    width: "100%",
    height: "100%",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    outline: "none",
  },
  listText: {
    flex: 1,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    outline: "none",
    backgroundColor: theme.palette.background,
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  inputLeft: {
    marginRight: theme.spacing(1),
  },
  tickContainer: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.green,
    height: "35px",
    width: "35px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    cursor: "pointer",
  },
  tickIcon: {
    width: "60%",
    height: "60%",
  },
  deleteIconContainer: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.red,
    height: "35px",
    width: "35px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    cursor: "pointer",
  },
  deleteIcon: {
    width: "50%",
    height: "50%",
  },
  listContainer: {
    display: "flex",
    height: "35px",
    flex: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    alignItems: "center",
  },
  errorText: {
    color: theme.palette.red,
    fontSize: "12px"
  },
}));

export default useStyles;
