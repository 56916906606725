import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.background,
    height: "100%",
    display: "flex",
    alignItems: "center",
    padding: "30px 30px",
  },
  container: {
    width: "100%",
    height: "100%",
    background: theme.palette.white,
    padding: theme.spacing(3),
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative"
  },
  headerContainer: {
    width: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    padding: theme.spacing(3),
  },
  contentContainer: {
    flex: 1,
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 800,
    width: "100%",
  },
  title: {
    fontSize: "2.5em",
  },
  instructions: {
    fontSize: "1.4em",
    color: theme.palette.darkGrey,
    textAlign: "center",
  },
  form: {
    width: "100%",
    maxWidth: 450,
    marginTop: theme.spacing(3),
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
  },
}));

export default useStyles;
