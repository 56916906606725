import React, { useState, useEffect } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";

import xlsxUtils from "../../utils/xlsx";
import ConfirmationPopup from "../../components/ConfirmationPopup";
import useStyles from "./style";
import MessageModal from "../../components/MessageModal";
import AppFrame from "../../components/AppFrame";
import TabBar from "../../components/TabBar";
import CustomerTable from "../../components/CustomerTable";
import FloatingButton from "../../components/FloatingButton";
import SearchBarWithFilter from "../../components/SearchBarWithFilter";
import AppHeader from "../../components/AppHeader";
import Button from "../../components/Button";
import api from "../../utils/api";
import deleteIcon from "../../assets/icons/delete.png";
import activeIcon from "../../assets/icons/active.png";
import inActiveIcon from "../../assets/icons/inActive.png";
// import detailsIcon from "../../assets/icons/info.png";
import editIcon from "../../assets/icons/edit.png";
import convertIcon from "../../assets/icons/convert.png";
import AddUserModal from "../../components/AddUserModal";
import Loader from "../../components/Loader";
import ExcelIcon from "../../assets/icons/excel.png";
import RefreshIcon from "../../assets/icons/refresh.png";
import globalHelpers from "../../utils/globalHelpers";
import userActions from "../../redux/user/action";

const { setUserData, clearUserData } = userActions;

const tabs = [
  {
    name: "Admin",
    code: "admin",
  },
  {
    name: "Field Agent",
    code: "agent",
  },
  {
    name: "Customer",
    code: "user",
  },
];

const CustomerDetails = () => {
  const classes = useStyles();
  // const history = useHistory();
  const [selectedTab, setSelectedTab] = useState("admin");
  const [userList, setUserList] = useState([]);
  const [tableTitle, setTableTitle] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(null);
  const [appLoading, setAppLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [screenLoading, setScreenLoading] = useState(false);
  const [editUserDetails, setEditUserDetails] = useState(null);
  const [confirmationPopup, setConfirmationPopup] = useState({});
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({});
  const [filters, setFilters] = useState([
    { name: "Email", key: "filterByEmail" },
  ]);
  const [filterValue, setFilterValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const state = useSelector((state) => state);
  const user = state?.auth?.user;
  const loggedInUserId = user?._id;
  const isSuperAdmin = user?.isSuperAdmin;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearUserData());
    setTableHeads(selectedTab);
    fetchCustomerList(selectedTab, {}, currentPage);

  
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    createTableData(selectedTab);
  }, [userList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (getUserDataFromRedux()) return;
    if (!Object.keys(selectedFilter).length) {
      fetchCustomerList(selectedTab, {}, currentPage);
    }
  }, [selectedTab, selectedFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  const getUserDataFromRedux = () => {
    const userData = { ...state.user };
    let shouldReturn = false;
    if (selectedTab === "admin" && userData?.admin?.data.length) {
      setUserList(userData.admin.data);
      setCurrentPage(userData.admin.page);
      setTotalPage(userData.admin.totalPage);
      shouldReturn = true;
    }
    if (selectedTab === "agent" && userData?.agent?.data.length) {
      setUserList(userData.agent.data);
      setCurrentPage(userData.agent.page);
      setTotalPage(userData.agent.totalPage);
      shouldReturn = true;
    }
    if (selectedTab === "user" && userData?.user?.data.length) {
      setUserList(userData.user.data);
      setCurrentPage(userData.user.page);
      setTotalPage(userData.user.totalPage);
      shouldReturn = true;
    }
    return shouldReturn;
  };
  const fetchCustomerList = async (
    tab,
    newSelectedFilter = {},
    page,
    persist = true
  ) => {
    try {
      setLoading(true);
      const userData = {};
      const payload = {
        filterByRole: tab,
        page,
      };

      if (!!Object.keys(selectedFilter) && filterValue) {
        payload[selectedFilter.key] = filterValue.trim();
      }

      const res = await api.fetchCustomerList(payload);

      setTotalPage(res.data.totalPage);
      if (persist) {
        if (tab === "admin") {
          userData.admin = {}
          userData.admin.data = res.data.users;
          userData.admin.page = page;
          userData.admin.totalPage = res.data.totalPage;
        }
        if (tab === "agent") {
          userData.agent = {}
          userData.agent.data = res.data.users;
          userData.agent.page = page;
          userData.agent.totalPage = res.data.totalPage;
        }
        if (tab === "user") {
          userData.user = {}
          userData.user.data = res.data.users;
          userData.user.page = page;
          userData.user.totalPage = res.data.totalPage;
        }
        dispatch(setUserData(userData));
      }
      setUserList(res.data.users);
    } catch (e) {
      setMessage({
        isError: true,
        title: "Error",
        message: e?.message,
      });
    }

    setLoading(false);
  };

  const changeTab = (tab) => {
    setUserList([]);
    setTableData([]);
    setSelectedTab(tab);
    setTableHeads(tab);
    setTabFilters(tab);
    setFilterValue("");
    setSelectedFilter({});
  };

  const setTableHeads = (tab) => {
    if (tab === "admin") {
      setTableTitle(["Name", "Email", "Role", "Last Login", "Action"]);
    }
    if (tab === "agent") {
      setTableTitle(["Name", "Email", "Last Login", "Action"]);
    }
    if (tab === "user") {
      setTableTitle(["Name", "Email", "Status", "Action"]);
    }
  };

  // const goToCustomerDetails = (user) => {
  //   history.push(`/customer-answers/${user._id}`);
  // };

  const createTableData = () => {
    if (selectedTab === "admin") {
      const data = userList.map((item) => {
        return [
          `${item?.profile?.firstName} ${item?.profile?.lastName}`,
          item.email,
          item.isSuperAdmin ? "Super Admin" : "Admin",
          item.lastLogin
            ? moment(item.lastLogin).format("MM/DD/YYYY hh:mm a")
            : "-",
          item._id !== loggedInUserId && isSuperAdmin ? (
            <div className={classes.actionBox}>
              <img
                onClick={() => onDeletePress(item)}
                data-for="admin-delete"
                data-tip="Delete"
                alt="delete"
                src={deleteIcon}
                className={classes.icon}
              />
              <ReactTooltip
                id="admin-delete"
                place="top"
                type="dark"
                effect="float"
              />
            </div>
          ) : (
            "-"
          ),
        ];
      });
      setTableData(data);
    }

    if (selectedTab === "agent") {
      const data = userList.map((item) => {
        return [
          `${item?.profile?.firstName} ${item?.profile?.lastName}`,
          item.email,
          item.lastLogin
            ? moment(item.lastLogin).format("MM/DD/YYYY hh:mm a")
            : "-",
          item._id !== loggedInUserId ? (
            <div className={classes.actionBoxAgent}>
              <img
                onClick={() => onEditPress(item)}
                data-for="edit-agent"
                data-tip="Edit"
                alt="edit"
                src={editIcon}
                className={classes.icon}
              />
              <ReactTooltip
                id="edit-agent"
                place="top"
                type="dark"
                effect="float"
              />

              <p className={classes.border} />

              <img
                onClick={() => onDeletePress(item)}
                data-for="delete-agent"
                data-tip="Delete"
                alt="delete"
                src={deleteIcon}
                className={classes.icon}
              />
              <ReactTooltip
                id="delete-agent"
                place="top"
                type="dark"
                effect="float"
              />
            </div>
          ) : (
            "-"
          ),
        ];
      });
      setTableData(data);
    }

    if (selectedTab === "user") {
      const data = userList.map((item) => [
        <Link to={`/customer-answers/${item._id}`} style={{ flex: 1 }}>
          {item?.profile?.firstName} {item?.profile?.lastName}
        </Link>,
        item.email,
        item.customerStatus ? item.customerStatus.name : "-",
        renderUserAction(item),
      ]);
      setTableData(data);
    }
  };

  const renderUserAction = (user) => {
    const customerStatus = user.customerStatus;
    if (customerStatus) {
      if (customerStatus.code === "new") {
        return (
          <div className={classes.actionBox}>
            {/* <Tooltip
              content="View details"
              onClick={() => goToCustomerDetails(user)}
            >
              <img alt="details" src={detailsIcon} className={classes.icon} />
            </Tooltip> */}

            <img
              onClick={() => changeCustomerStatus(user, "active")}
              data-for="convert-to-customer"
              data-tip="Convert to customer"
              alt="convert"
              src={convertIcon}
              className={classes.icon}
            />
            <ReactTooltip
              id="convert-to-customer"
              place="top"
              type="dark"
              effect="float"
            />

            <p className={classes.border} />
            <img
              data-tip="Edit"
              data-for="edit-customer"
              onClick={() => onEditPress(user)}
              alt="edit"
              src={editIcon}
              className={classes.icon}
            />
            <ReactTooltip
              id="edit-customer"
              place="top"
              type="dark"
              effect="float"
            />
            <p className={classes.border} />
            <img
              onClick={() => onDeletePress(user)}
              data-for="delete-customer"
              data-tip="Delete"
              alt="delete"
              src={deleteIcon}
              className={classes.icon}
            />
            <ReactTooltip
              id="delete-customer"
              place="top"
              type="dark"
              effect="float"
            />
          </div>
        );
      }

      if (customerStatus.code === "active") {
        return (
          <div className={classes.actionBox}>
            {/* <Tooltip
              content="View details"
              onClick={() => goToCustomerDetails(user)}
            >
              <img alt="details" src={detailsIcon} className={classes.icon} />
            </Tooltip> */}

            <img
              alt="active"
              src={activeIcon}
              className={classes.icon}
              data-for="active-customer"
              data-tip="Active"
              onClick={() => changeCustomerStatus(user, "inactive")}
            />
            <ReactTooltip
              id="active-customer"
              place="top"
              type="dark"
              effect="float"
            />

            <p className={classes.border} />
            <img
              onClick={() => onEditPress(user)}
              data-for="edit-customer"
              data-tip="Edit"
              alt="edit"
              src={editIcon}
              className={classes.icon}
            />
            <ReactTooltip
              id="edit-customer"
              place="top"
              type="dark"
              effect="float"
            />

            <p className={classes.border} />
            <img
              onClick={() => onDeletePress(user)}
              data-for="delete-customer"
              data-tip="Delete"
              alt="delete"
              src={deleteIcon}
              className={classes.icon}
            />
            <ReactTooltip
              id="delete-customer"
              place="top"
              type="dark"
              effect="float"
            />
          </div>
        );
      }

      if (customerStatus.code === "inactive") {
        return (
          <div className={classes.actionBox}>
            <img
              onClick={() => changeCustomerStatus(user, "active")}
              data-for="inactive-customer"
              data-tip="Inactive"
              alt="inactive"
              src={inActiveIcon}
              className={classes.icon}
            />
            <ReactTooltip
              id="inactive-customer"
              place="top"
              type="dark"
              effect="float"
            />

            {/* <Tooltip
              content="View details"
              onClick={() => goToCustomerDetails(user)}
            >
              <img alt="details" src={detailsIcon} className={classes.icon} />
            </Tooltip> */}
            <p className={classes.border} />

            <img
              onClick={() => onEditPress(user)}
              data-for="edit-customer"
              data-tip="Edit"
              alt="edit"
              src={editIcon}
              className={classes.icon}
            />
            <ReactTooltip
              id="edit-customer"
              place="top"
              type="dark"
              effect="float"
            />

            <p className={classes.border} />
            <img
              onClick={() => onDeletePress(user)}
              data-for="delete-customer"
              data-tip="Delete"
              alt="delete"
              src={deleteIcon}
              className={classes.icon}
            />
            <ReactTooltip
              id="delete-customer"
              place="top"
              type="dark"
              effect="float"
            />
          </div>
        );
      }
    } else {
      return (
        <div className={classes.actionBox}>
          {/* <Tooltip
            content="View details"
            onClick={() => goToCustomerDetails(user)}
          >
            <img alt="details" src={detailsIcon} className={classes.icon} />
            
          </Tooltip> */}

          <img
            onClick={() => onEditPress(user)}
            data-for="edit-customer"
            data-tip="Edit"
            alt="edit"
            src={editIcon}
            className={classes.icon}
          />
          <ReactTooltip
            id="edit-customer"
            place="top"
            type="dark"
            effect="float"
          />
        </div>
      );
    }
  };

  const handleCloseModal = () => {
    setMessage({});
  };

  const getAppFrameTitle = () => {
    let name = "Admins";

    if (selectedTab === "agent") {
      name = "Agents";
    }
    if (selectedTab === "user") {
      name = "Customers";
    }
    return name;
  };

  const openModal = (value) => {
    setModalOpen(true);
  };

  const onClose = () => {
    setModalOpen(false);
    setEditUserDetails(null);
  };

  const changeCustomerStatus = async (user, status) => {
    try {
      setScreenLoading(true);
      const payload = {
        user: user._id,
        status,
      };
      const res = await api.changeCustomerStatus(payload);
      const userIndex = userList.findIndex(
        (item) => item._id === res.data.user._id
      );

      if (userIndex !== -1) {
        userList[userIndex] = res.data.user;
        setUserList([...userList]);
      }

      let message = "User is now an active customer";

      if (status === "inactive") {
        message = "User is no longer an active customer";
      }

      setMessage({
        isError: false,
        title: "Success",
        message,
      });
    } catch (e) {
      setMessage({
        isError: true,
        title: "Error",
        message: e?.response?.data?.message || e?.message,
      });
      console.log("E");
    }
    setScreenLoading(false);
  };

  const saveUser = async (userData) => {
    try {
      setAppLoading(true);
      await api.addUser(userData);
      setMessage({
        isError: false,
        title: "Success",
        message: "User has been added",
      });
      fetchCustomerList(selectedTab, {}, currentPage);
      onClose();
    } catch (e) {
      setMessage({
        isError: true,
        title: "Error",
        message: e?.response?.data?.message || e?.message,
      });
    }

    setAppLoading(false);
  };

  const onEditPress = (data) => {
    setEditUserDetails(data);
    setModalOpen(true);
  };

  const onDeletePress = (user) => {
    setConfirmationPopup({
      open: true,
      onConfirm: () => onDeleteConfirm(user),
      onCancel: () => onDeleteCancel(user),
      text: `Are you sure you want to delete ${user.profile.firstName}?`,
    });
  };

  const editUser = async (userData) => {
    try {
      userData.user = editUserDetails._id;
      setAppLoading(true);
      await api.editProfile(userData);
      setMessage({
        isError: false,
        title: "Success",
        message: "User has been updated",
      });
      fetchCustomerList(selectedTab, {}, currentPage);
      onClose();
    } catch (e) {
      setMessage({
        isError: true,
        title: "Error",
        message: e?.response?.data?.message || e?.message,
      });
    }
    setAppLoading(false);
  };

  const onDeleteCancel = () => {
    setConfirmationPopup({});
  };

  const onDeleteConfirm = async (user) => {
    try {
      const payload = {
        user: user._id,
      };

      setAppLoading(true);
      await api.deleteUser(payload);

      setMessage({
        isError: false,
        title: "Success",
        message: "User has been deleted",
      });

      // fetchCustomerList(selectedTab);
      setConfirmationPopup({});
      const userIndex = userList.findIndex((item) => item._id === user._id);
      userList.splice(userIndex, 1);
      setUserList([...userList]);
    } catch (e) {
      setMessage({
        isError: true,
        title: "Error",
        message: e?.response?.data?.message || e?.message,
      });
    }

    setAppLoading(false);
  };

  const onFilterChange = (filter) => {
    setFilterValue("");
    setSelectedFilter(filter);
    setShowFilterDropdown(false);
  };

  const toggleFilterDropdown = (e) => {
    setShowFilterDropdown(!showFilterDropdown);
  };

  const setTabFilters = (tab) => {
    if (tab === "admin" || tab === "agent") {
      const filters = [{ name: "Email", key: "filterByEmail" }];
      setFilters(filters);
    }
    if (tab === "user") {
      const filters = [
        { name: "Email", key: "filterByEmail" },
        { name: "Status", key: "filterByCustomerStatus" },
      ];

      setFilters(filters);
    }
  };

  const onSearch = (e) => {
    e.preventDefault();
    const page = 1;
    setCurrentPage(page);
    if (!Object.keys(selectedFilter).length || !filterValue) return;
    fetchCustomerList(selectedTab, {}, page, false);
  };

  const onTextChange = (e) => {
    setFilterValue(e.target.value);
  };

  const clearFilter = () => {
    const tab = selectedTab;
    setSelectedTab(tab);
    setSelectedFilter({});
    setFilterValue("");
  };

  const combineAnswers = (answers) => {
    if (!Array.isArray(answers)) {
      return "";
    }
    return answers.map((item) => item.answer).join(", ");
  };

  const exportToExcel = async () => {
    try {
      setAppLoading(true);
      const response = await api.getAllCustomers();

      const data = response.data.submissions.map((sub) => {
        const rowData = sub.questions.reduce((acc, ques) => {
          acc[ques.question.text] = combineAnswers(ques.answers);
          return acc;
        }, {});

        const powerConsumption = Object.keys(sub.powerConsumption);
        powerConsumption.forEach((item) => {
          const capitalizedName = globalHelpers.capitalizeFirstLetter(item);
          const label = `${capitalizedName} - Estimated Power Req (W)`;
          rowData[label] = sub.powerConsumption[item];
        });

        rowData["Tier according to total consumption"] = sub?.tier?.name;
        return rowData;
      });

      xlsxUtils.exportToExcel(data, "solad-lead");
    } catch (e) {
      setMessage({
        isError: true,
        title: "Error",
        message: e?.message,
      });
    }
    setAppLoading(false);
  };

  const onPageChange = (_, page) => {
    setCurrentPage(page);
    fetchCustomerList(selectedTab, {}, page);
  };

  const reloadData = () => {
    setCurrentPage(1)
    fetchCustomerList(selectedTab, {}, 1);

  }
  return (
    <div className={classes.mainContainer}>
      {screenLoading && <Loader />}
      {modalOpen && (
        <AddUserModal
          open={modalOpen}
          onClose={onClose}
          selectedTab={selectedTab}
          onSave={saveUser}
          editUserData={editUserDetails}
          onUpdatePress={editUser}
        />
      )}
      <div
        className={classes.container}
        onClick={() => setShowFilterDropdown(false)}
      >
        <AppFrame name={getAppFrameTitle()}>
          <div className={classes.contentContainer}>
            {selectedTab !== "user" && <FloatingButton onClick={openModal} />}
            <AppHeader />
            <div className={classes.tabBarSection}>
              <TabBar
                tabs={tabs}
                selectedTab={selectedTab}
                onChange={changeTab}
              />
            </div>
            <div className={classes.searchSection}>
              <form
                onSubmit={onSearch}
                className={classes.searchBox}
                onClick={(e) => e.stopPropagation()}
              >
                <SearchBarWithFilter
                  containerStyle={{
                    border: "none",
                    borderRadius: 0,
                    flex: 1,
                    padding: "0 0 0 10px",
                    borderTopLeftRadius: "10px",
                    borderBottomLeftRadius: "10px",
                  }}
                  filters={filters}
                  onFilterChange={onFilterChange}
                  toggleFilterDropdown={toggleFilterDropdown}
                  showFilterDropdown={showFilterDropdown}
                  selectedFilter={selectedFilter.name}
                  onChangeText={onTextChange}
                  value={filterValue}
                />
                <Button
                  title="Search"
                  buttonStyle={{
                    border: "none",
                    borderRadius: 0,
                    width: "100px",
                    fontSize: "1em",
                    borderTopRightRadius: "10px",
                    borderBottomRightRadius: "10px",
                  }}
                  type="Submit"
                />
              </form>
              <div className={classes.refreshBox} onClick={reloadData}>
                <button className={classes.refreshText}>Refresh</button>
                <img src={RefreshIcon} className={classes.refreshIcon} />
              </div>
            </div>
            {!!Object.keys(selectedFilter).length && (
              <p className={classes.clearFilterText} onClick={clearFilter}>
                Clear Filter
              </p>
            )}

            {selectedTab === "user" && (
              <div className={classes.excelButtonContainer}>
                <div onClick={exportToExcel} className={classes.excelButton}>
                  <span>Export</span>
                  <img className={classes.excelIcon} src={ExcelIcon} alt="" />
                </div>
              </div>
            )}

            <div className={classes.tableContainer}>
              {showFilterDropdown && <div className={classes.backdrop} />}
              <CustomerTable
                loading={loading}
                userList={userList}
                tableData={tableData}
                selectedTab={selectedTab}
                tableTitle={tableTitle}
                onPageChange={onPageChange}
                currentPage={currentPage}
                totalPage={totalPage}
              />
            </div>
          </div>
        </AppFrame>
      </div>
      <MessageModal data={message} handleClose={handleCloseModal} />
      <ConfirmationPopup data={confirmationPopup} />
      {appLoading && <Loader />}
    </div>
  );
};

export default CustomerDetails;
