import React from "react";
import { Modal, Fade } from "@material-ui/core";
import useStyles from "./style.js";

import circleCheck from "../../assets/icons/circleCheck.png";
import circleCross from "../../assets/icons/circleCross.png";

const MessageModal = (props) => {
  const classes = useStyles();
  const { data = {}, handleClose } = props;
  const { isError, message, title, confirmText = "Try Again" } = data;

  const handleCloseClick = () => {
    if (handleClose) {
      handleClose();
    }

    if (data.onConfirm) {
      data.onConfirm();
    }
  };

  return (
    <Modal
      className={classes.modal}
      open={!!message}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade timeout={{ appear: 0, enter: 500 }} in={message}>
        {!!message ? (
          <div className={classes.paper}>
            <div
              className={`${classes.circle} ${
                isError ? classes.error : classes.success
              }`}
            >
              {isError ? (
                <img
                  alt="error"
                  src={circleCross}
                  className={`${classes.circleIcon} ${
                    isError ? classes.error : classes.success
                  }`}
                />
              ) : (
                <img
                  alt=""
                  src={circleCheck}
                  className={`${classes.circleIcon} ${
                    isError ? classes.error : classes.success
                  }`}
                />
              )}
            </div>
            <div>
              <div className={classes.titleBox}>
                <p className={classes.title}>{title}</p>
                <div
                  className={`${classes.borderLine} ${
                    isError ? classes.error : classes.success
                  }`}
                />
              </div>
              <div className={classes.messageBox}>
                <p className={classes.message}>{message}</p>
              </div>
              {isError ? (
                <p
                  className={classes.actionTextError}
                  onClick={handleCloseClick}
                >
                  {confirmText}
                </p>
              ) : (
                <p
                  className={classes.actionTextSuccess}
                  onClick={handleCloseClick}
                >
                  Continue
                </p>
              )}
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </Fade>
    </Modal>
  );
};

export default MessageModal;
