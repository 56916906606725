import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    width: "100%",
    height: "100%",
    maxWidth: "600px",
    maxHeight: "450px",
    background: theme.palette.white,
    outline: "none",
    borderRadius: "10px",
    margin: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    fontSize: "1.6rem",
    flex: 1,
  },
  crossIcon: {
    width: "30px",
    cursor: "pointer",
  },
  form: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  verticalSeperator: {
    height: theme.spacing(4),
  },
  buttonContainer: {
    alignSelf: "flex-start",
    width: "130px",
  },
}));

export default useStyles;
