import React, { useState } from "react";

import CheckedIcon from "../../../assets/icons/checked.png";
import DeleteWhiteIcon from "../../../assets/icons/delete-white.png";
import globalHelpers from "../../../utils/globalHelpers";
import useStyles from "./style";

const DropdownOptionsList = (props) => {
  const [label, setLabel] = useState("");
  const [value, setValue] = useState("");
  const [errors, setErrors] = useState({});
  const classes = useStyles();

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    const errors = validation();

    if (errors) {
      return;
    }

    const payload = {
      label: label,
      value: value,
    };

    props.onSubmit(payload);
  };

  const validation = () => {
    const newErrors = {};

    if (!label) {
      newErrors.label = "This field is required";
    }

    if (!value) {
      newErrors.value = "This field is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length;
  };

  const handleDelete = () => {
    props.onDelete();
  };

  if (props.editEnabled) {
    return (
      <form onSubmit={handleSubmit} className={classes.container}>
        <div className={`${classes.inputContainer} ${classes.inputLeft}`}>
          <input
            className={`${classes.input}`}
            type="text"
            value={label}
            onChange={(e) => {
              setLabel(e.target.value);

              setValue(globalHelpers.replaceSpaceWithDash(e.target.value));
            }}
            placeholder="Label*"
          />
          {!!errors.label && (
            <span className={classes.errorText}>{errors.label}</span>
          )}
        </div>

        <div className={classes.inputContainer}>
          <input
            className={classes.input}
            value={value}
            type="text"
            onChange={(e) => setValue(e.target.value)}
            placeholder="Value*"
          />
          {!!errors.value && (
            <span className={classes.errorText}>{errors.value}</span>
          )}
        </div>
        <div onClick={() => handleSubmit()} className={classes.tickContainer}>
          <img className={classes.tickIcon} src={CheckedIcon} alt="checked" />
        </div>
      </form>
    );
  } else {
    return (
      <div className={classes.listContainer}>
        <div className={`${classes.listText} ${classes.inputLeft}`}>
          {props.data.label}
        </div>
        <div className={classes.listText}>{props.data.value}</div>
        <div className={classes.deleteIconContainer}>
          <img
            onClick={handleDelete}
            className={classes.deleteIcon}
            src={DeleteWhiteIcon}
            alt="delete"
          />
        </div>
      </div>
    );
  }
};

DropdownOptionsList.defaultProps = {
  editEnabled: false,
  allData: [],
  data: {},
};

export default DropdownOptionsList;
