import React, { useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import axios from "axios";

import store, { persistor } from "./redux/index";
import Router from "./router";
import colors from "./config/colors";

const theme = createTheme({
  palette: {
    primary: {
      main: colors.PRIMARY,
    },
    secondary: {
      main: colors.SECONDARY,
    },
    background: colors.BACKGROUND,
    white: colors.WHITE,
    orange: colors.ORANGE,
    lightGrey: colors.LIGHT_GREY,
    lightOrange: colors.LIGHT_ORANGE,
    mediumGrey: colors.MEDIUM_GREY,
    grey: colors.GREY,
    darkGrey: colors.DARKGREY,
    black: colors.BLACK,
    themeBlue: colors.THEME_BLUE,
    green: colors.SUCCESS,
    lightBlack: colors.LIGHT_BLACK,
    red: colors.RED,
    lightAqua: colors.LIGHT_AQUA,
    iconBlue: colors.ICON_BLUE,
  },
});

function App() {
  useEffect(() => {
    const persistRoot = JSON.parse(
      sessionStorage.getItem("persist:root") || "{}"
    );
    const auth = JSON.parse(persistRoot.auth || "{}");

    if (auth.authToken) {
      axios.defaults.headers.common["Authorization"] = auth.authToken;
    }
  }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router />
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
