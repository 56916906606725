import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    backgroundColor: theme.palette.white,
    borderColor: theme.palette.grey,
    borderWidth: 2,
    borderStyle: "solid",
    borderRadius: "15px",
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  chartHeadingContainer: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(0.5),
  },
  chartHeading: {
    fontSize: "1.2em",
    fontWeight: "bold",
    color: theme.palette.secondary.main,
  },
  chartContainer: {
    flex: 1,
    paddingRight: theme.spacing(2),
    overflow: "hidden",
  },
  loaderContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default useStyles;
