import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.background,
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  container: {
    width: "100%",
    height: "100%",
    padding: "30px 30px",
  },
  frameContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentContainer: {
    backgroundColor: "white",
    width: "100%",
    padding: theme.spacing(3),
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    overflowY: "auto",
  },

  title: {
    fontSize: "2.2em",
  },
  dataContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  form: {
    width: "100%",
    maxWidth: "1000px",
    marginTop: theme.spacing(2),
  },
  formRow: {
    display: "flex",
    flexDirection: "row",
    marginTop: theme.spacing(2),
  },

  inputField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  seperator: {
    width: theme.spacing(4),
  },
  buttonContainer: {
    marginTop: theme.spacing(3),
    maxWidth: "300px",
    width: "100%",
    margin: "0 auto",
  },
}));

export default useStyles;
