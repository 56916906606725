import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: "1em",
    cursor: "pointer",
    fontWeight: "bold"
  },
}));

export default useStyles;
