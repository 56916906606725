import React from "react";
import useStyles from "./style";
import { Typography } from "@material-ui/core";

const TabBar = (props) => {
  const classes = useStyles();
  const { tabs, selectedTab, onChange } = props;
  return (
    <div className={classes.container}>
      <div className={classes.tabContainer}>
        {tabs.map(tab => (
          <div className={classes.tabBox} onClick={() => onChange(tab.code)}>
            <Typography variant="p" className={classes.tabName}>
              {tab.name}
            </Typography>
            {selectedTab === tab.code && <div className={classes.activeDot} />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TabBar;
