import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.white,
    padding: theme.spacing(1),
    outline: "none",
    position: "relative",
    overflow: "auto",
    width: "94%",
    height: "90%",
    borderRadius: 10,
  },
  container: {
    padding: theme.spacing(6),
  },
  title: {
    fontSize: "1.9em",
    marginTop: theme.spacing(3),
  },
  row: {
    display: "flex",
    marginTop: theme.spacing(4)
  },
  roleBox: {
    marginTop: theme.spacing(5),
    borderTop: `1px solid ${theme.palette.lightGrey}`
  },
  checkBoxSection: {
    display: "flex",
    marginTop: theme.spacing(2)
  },
  closeIconBox: {
    display: "flex",
    justifyContent: "flex-end"
  },
  closeIcon: {
    width: "25px",
    cursor: "pointer"
  }
}));

export default useStyles;
