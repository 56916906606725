import Collapsible from "react-collapsible";
import useStyles from "./style";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useTheme } from "@material-ui/core/styles";

const CollapseableBox = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { mainLabel, dataTable } = props;

  const arrowDropDown = () => {
    return (
      <div className={classes.arrowDropDownContainer}>
        {mainLabel}
        <ArrowDropDownIcon style={{ color: theme.palette.orange }} />
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <Collapsible className={classes.collapseable} trigger={arrowDropDown()}>
        {dataTable}
      </Collapsible>
    </div>
  );
};

export default CollapseableBox;
