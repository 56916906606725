import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backgroundImageCircleContainer: {
    position: "absolute",
    left: 0,
    bottom: 0,
    overflow: "hidden",
    width: "100%",
    height: "100%",
  },
  backgroundImagePolygonContainer: {
    position: "absolute",
    right: 0,
    top: 0,
    overflow: "hidden",
    width: "100%",
    height: "100%",
  },
  backgroundImageCircle: {
    position: "absolute",
    left: -350,
    bottom: -350,
    width: "700px",
    height: "700px",
  },
  backgroundImagePolygon: {
    position: "absolute",
    right: -200,
    top: -200,
    width: "700px",
    height: "700px",
  },
}));

export default useStyles;
