import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  container: {
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 10000,
    backgroundColor: "#3737378f",
  },
  loader: {
    width: "65px",
    height: "65px",
    animation: "spin 1s infinite linear",
  },
}));

export default useStyles;
