import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: "100%",
    position: "relative"
  },
  container: {
    border: `1px solid ${theme.palette.lightGrey}`,
    borderRadius: "5px",
    backgroundColor: "#fcfcfc",
  },
  input: {
    height: "100%",
  },
  endAdornment: {
    height: "100%",
    // backgroundColor: theme.palette.lightGrey,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderLeft: `1px solid ${theme.palette.lightGrey}`,
    marginLeft: theme.spacing(1.2),
    padding: "3px",
    cursor: "pointer",
    maxWidth: "190px"
  },
  inputField: {
    paddingLeft: theme.spacing(1.2),
    height: "100%"
  },
  filterIcon: {
    width: 25,
  },
  selectedFilterBox:{
    display: "flex",
    width:" max-content",
    alignItems: "center",
    padding: "5px",
  },
  selectedFilter: {
    marginRight: theme.spacing(1)
  }
}));

export default useStyles;
