import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "300px",
    flex: 1,
    backgroundColor: theme.palette.white,
    borderColor: theme.palette.grey,
    borderWidth: 2,
    borderStyle: "solid",
    borderRadius: "15px",
    display: "flex",
    flexDirection: "row",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    position: "relative",
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    zIndex: 10,
  },
  chartHeadingContainer: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(0.5),
  },
  chartHeading: {
    fontSize: "1.2em",
    fontWeight: "bold",
    color: theme.palette.secondary.main,
  },
  chartSecondHeading: {
    fontSize: "1em",
    color: theme.palette.darkGrey,
  },
  chartContainer: {
    flex: 1,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
  },
  backgroundIconContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
    position: "absolute",
    justifyContent: "center",
    zIndex: 5,
  },
  shieldIcon: {
    height: "70%",
    width: "70%",
    objectFit: "contain",
  },
}));

export default useStyles;
