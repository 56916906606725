import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router";
import { useTheme } from "@material-ui/core/styles";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import SelectInput from "../../components/SelectInput";
import editIcon from "../../assets/icons/edit.png";
import useStyles from "./style";
import MessageModal from "../../components/MessageModal";
import AppFrame from "../../components/AppFrame";
import AppHeader from "../../components/AppHeader";
import Loader from "../../components/Loader";
import CollapseableBox from "../../components/CollapseableBox";
import api from "../../utils/api";
import AddIcon from "@material-ui/icons/AddCircle";

const Questions = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState({});
  const [sequence, setSequence] = useState();
  const [subCategories, setSubCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [allQuestion, setAllQuestions] = useState([]);
  const [finalSubCategories, setFinalSubCategories] = useState([]);

  const columns = [
    {
      name: "Question",
      selector: (row) => row.question.text,
      sortable: true,
      wrap: true,
    },
    {
      name: "Mandatory",
      selector: (row) => JSON.stringify(row.mandatory),
      sortable: true,
      width: "130px",
    },
    {
      name: "Sequence",
      selector: (row) => row.sequence,
      sortable: true,
      width: "130px",
    },
    {
      name: "UI Control",
      selector: (row) => row.question.element.type.name,
      sortable: true,
    },
    {
      name: "Tag",
      selector: (row) =>
        row.question.useForEnergyConsumption
          ? row.question.element.applianceType.name
          : "NONE",
      sortable: true,
      width: "130px",
    },
    {
      name: "Actions",
      width: "100px",
      cell: (row) => {
        return (
          <div>
            <img
              style={{ width: 25 }}
              onClick={() => {
                navigateToAddQuestions(row);
              }}
              src={editIcon}
              alt="edit icon"
            />
            <DeleteForeverIcon
              onClick={() => {
                deleteQuestion(row);
              }}
              style={{ color: theme.palette.iconBlue }}
            />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    fetchQuestions();
    // maxSequence();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchQuestions = async () => {
    try {
      setLoading(true);

      const response = await api.fetchQuestions();
      const sequence = Math.max.apply(
        Math,
        response.data.questionnaire.questions.map(function (value) {
          return value.sequence;
        })
      );

      setSequence(sequence);

      const rawQuestions = response.data.questionnaire.questions;
      const finalCategories = [];

      rawQuestions.forEach((item) => {
        const isCategory = finalCategories.some(
          (cat) => cat._id === item.category._id
        );

        if (!isCategory) {
          finalCategories.push({
            ...item.category,
            label: item.category.name,
            value: item.category._id,
          });
        }
      });

      if (finalCategories[0]) {
        setSelectedCategory(finalCategories[0]._id);
      }

      const finalQuestions = decideQuestions(
        rawQuestions,
        finalCategories[0]._id
      );

      setSubCategories(finalQuestions);
      setCategories([...finalCategories]);
      setAllQuestions(rawQuestions);
    } catch (e) {
      setMessage({
        isError: true,
        title: "Error",
        message: e.message,
      });
    }

    setLoading(false);
  };

  const deleteQuestion = async (value) => {
    try {
      setLoading(true);

      const payload = {
        _id: value.question._id,
      };

      await api.deleteQuestion(payload);

      for (var i = 0; i < allQuestion.length; i++)
        if (allQuestion[i].question._id === value.question._id) {
          allQuestion.splice(i, 1);
          break;
        }

      const finalQuestions = decideQuestions(allQuestion, selectedCategory);

      setSubCategories(finalQuestions);
    } catch (e) {
      setMessage({
        isError: true,
        title: "Error",
        message: e.message,
      });
    }

    setLoading(false);
  };

  const navigateToAddQuestions = (value) => {
    const categoriesMap = {};
    const categories = [];
    const subCategoriesMap = {};
    const subCategories = [];

    if (value) {
      allQuestion.forEach((item) => {
        if (
          item.question._id === value.question._id &&
          !categoriesMap[item.category._id]
        ) {
          categoriesMap[item.category._id] = true;
          categories.push(item.category);
        }

        if (
          item.question._id === value.question._id &&
          !subCategoriesMap[item.subCategory._id]
        ) {
          subCategoriesMap[item.subCategory._id] = true;
          subCategories.push(item.subCategory);
        }
      });

      value.categories = categories;
      value.subCategories = subCategories;
    }

    history.push({
      pathname: "/add-questions",
      value,
      sequence,
    });
  };

  const dataTable = (value) => {
    return <DataTable columns={columns} data={value} />;
  };

  const decideQuestions = (rawQuestions, rawSelectedCategory) => {
    const finalQuestions = [];

    rawQuestions.forEach((item) => {
      if (rawSelectedCategory && item.category._id !== rawSelectedCategory) {
        return;
      }

      const subCategoryIndex = finalQuestions.findIndex(
        (q) => item.subCategory._id === q._id
      );

      if (subCategoryIndex !== -1) {
        return finalQuestions[subCategoryIndex].questions.push(item);
      }

      finalQuestions.push({ ...item.subCategory, questions: [item] });
    });

    setFinalSubCategories(finalQuestions);
    return finalQuestions;
  };

  const collapse = () => {
    return subCategories.map((item) => (
      <CollapseableBox
        key={item._id}
        data={item}
        mainLabel={item.name}
        dataTable={dataTable(item.questions)}
      />
    ));
  };

  const handleCloseModal = () => {
    setMessage({});
  };

  const handleCategorySelect = (val) => {
    setSelectedCategory(val);

    if (val) {
      const finalQuestions = decideQuestions(allQuestion, val);
      setSubCategories(finalQuestions);
    }
  };

  return (
    <div className={classes.mainContainer}>
      {loading && <Loader />}
      <MessageModal data={message} handleClose={handleCloseModal} />
      <div className={classes.container}>
        <AppFrame>
          <div className={classes.contentContainer}>
            <AppHeader />
            <div className={classes.heading}>
              <h1>QUESTIONS </h1>
              <AddIcon
                className={classes.addIcon}
                onClick={() => {
                  navigateToAddQuestions();
                }}
              />
            </div>

            <SelectInput
              placeholder="Select client type"
              style={{ paddingTop: 10, paddingBottom: 10, width: "300px" }}
              options={categories}
              value={selectedCategory}
              onChange={handleCategorySelect}
            />
            {finalSubCategories.length < 1 ? (
              <p className={classes.noContent}>No content available to show</p>
            ) : (
              <div className={classes.tableContainer}>{collapse()}</div>
            )}
          </div>
        </AppFrame>
      </div>
    </div>
  );
};

export default Questions;
