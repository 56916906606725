import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useExcelDownloder } from "react-xls";

import ExcelIcon from "../../assets/icons/excel.png";
import BackButton from "../../components/BackButton";
import api from "../../utils/api";
import useStyles from "./style";
import MessageModal from "../../components/MessageModal";
import AppFrame from "../../components/AppFrame";
import AppHeader from "../../components/AppHeader";
import Loader from "../../components/Loader";
import OutlinedInput from "../../components/OutlinedInput";
import ImageQuestionInput from "../../components/ImageQuestionInput";
import AdditionalLoadQuestionInput from "../../components/AdditionalLoadQuestionInput";
import MultiImageQuestionInput from "../../components/MultiImageQuestionInput";

const CustomerAnswers = () => {
  const classes = useStyles();
  const [message, setMessage] = useState({});
  const [answers, setAnswers] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [tier, setTier] = useState(null);
  const [powerRequirements, setPowerRequirements] = useState([]);
  const [loading, setLoading] = useState(false);
  const locationParams = useParams();
  const { ExcelDownloder, Type } = useExcelDownloder();

  useEffect(() => {
    fetchAnswers();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchAnswers = async () => {
    try {
      setLoading(true);

      const response = await api.getCustomerById({ user: locationParams.id });
      const localQuestions = response.data.submission.questions;
      const filteredQuestions = localQuestions.reduce((acc, item) => {
        let foundIndex = acc.findIndex(
          (item2) => item2.name === item.subCategory.name
        );

        if (foundIndex === -1) {
          acc.push({ name: item.subCategory.name, questions: [] });
          foundIndex = acc.length - 1;
        }

        acc[foundIndex].questions.push(item);

        return acc;
      }, []);

      const powerRequirements = {};

      filteredQuestions.forEach((fQ) => {
        fQ.questions.forEach((item) => {
          const q = item.question;
          if (q.useForEnergyConsumption) {
            if (item.elementTypeCode === "additional-load") {
              const type = q.element.applianceType.name;
              const additionalLoad = item.answers.reduce((acc, ans) => {
                const additionalItem = ans.answer.split("||");
                const load = Number(additionalItem[1].split(":")[1]);
                return acc + load;
              }, 0);
              powerRequirements[type] = additionalLoad;
            } else {
              const type = q.element.applianceType.name;
              let quantity = Number(item.answers[0].answer);
              quantity = isNaN(quantity) ? 1 : quantity;

              if (type in powerRequirements) {
                powerRequirements[type] =
                  powerRequirements[type] + q.watt * quantity;
              } else {
                powerRequirements[type] = q.watt * quantity;
              }
            }
          }
        });
      });

      setExcelData(
        prepareExcelData(
          filteredQuestions,
          powerRequirements,
          response.data.submission.tier
        )
      );
      setAnswers(filteredQuestions);
      setTier(response.data.submission.tier);
      setPowerRequirements(powerRequirements);
    } catch (e) {
      setMessage({
        isError: true,
        title: "Error",
        message: e.message,
      });
    }

    setLoading(false);
  };

  const combineAnswers = (answers) => {
    if (!Array.isArray(answers)) {
      return "";
    }
    return answers.map((item) => item.answer).join(", ");
  };

  const prepareExcelData = (data, _powerRequirements, tier) => {
    const excelData = [];
    for (let subCat of data) {
      subCat.questions.forEach((item) => {
        const newData = {
          "Sub Category": item.subCategory.name,
          Question: item.question.text,
          Answer: combineAnswers(item.answers),
        };
        excelData.push(newData);
      });
    }

    const powerRequirementsArray = Object.keys(_powerRequirements);

    if (powerRequirementsArray.length) {
      powerRequirementsArray.forEach((item) => {
        const newData = {
          "Sub Category": "Power Requirements",
          Question: `${item} - Estimated Power Req (W)`,
          Answer: _powerRequirements[item],
        };
        excelData.push(newData);
      });
      const newData = [
        {
          "Sub Category": "Power Requirements",
          Question: "Subtotal",
          Answer: Object.values(_powerRequirements).reduce(
            (acc, item) => acc + item
          ),
        },
        {
          "Sub Category": "Power Requirements",
          Question: "Tier according to total consumption",
          Answer: tier?.name,
        },
      ];

      excelData.push(...newData);
    }

    console.log("excelData =>", excelData);

    return excelData;
  };

  const handleCloseModal = () => {
    setMessage({});
  };

  const answerToText = (item) => {
    if (item.question.defaultValue === "{{timestamp}}") {
      return moment(item.answers[0].answer).format("DD-MMM-YYYY HH:MM");
    }

    return item.answers.map((answerItem) => answerItem.answer).join(", ");
  };

  const answerFromSelectToText = (item) => {
    return item.answers
      .map((answerItem) => {
        const foundItem = item.question.element.options.find(
          (item2) => item2.value === answerItem.answer
        );
        return foundItem ? foundItem.label : answerItem.answer;
      })
      .join(", ");
  };

  return (
    <div className={classes.mainContainer}>
      {loading && <Loader />}
      <div className={classes.container}>
        <AppFrame name="Answers">
          <div className={classes.contentContainer}>
            <AppHeader />
            <div className={classes.backButtonContainer}>
              <BackButton />
              {!!excelData.length && (
                <ExcelDownloder
                  data={{
                    lead: excelData,
                  }}
                  filename={"book"}
                  className={classes.excelButton}
                  type={Type.Button} // or type={'button'}
                >
                  <span>Export</span>
                  <img className={classes.excelIcon} src={ExcelIcon} alt="" />
                </ExcelDownloder>
              )}
            </div>
            <div className={classes.contentInnerContainer}>
              {answers.map((mainItem, i) => {
                return (
                  <div key={i} className={classes.sectionContainer}>
                    <h2 className={classes.sectionHeading}>{mainItem.name}</h2>
                    <Grid container spacing={2}>
                      {mainItem.questions.map((item) => {
                        const textInputTypes = [
                          "default",
                          "number-pad",
                          "counter",
                          "phone-number",
                          "hours-selector",
                        ];
                        const dropdownInputTypes = [
                          "dropdown",
                          "circle-select",
                        ];

                        if (textInputTypes.includes(item.elementTypeCode)) {
                          return (
                            <Grid
                              key={item.question._id}
                              item
                              xs={12}
                              sm={12}
                              md={6}
                            >
                              <OutlinedInput
                                disabled={true}
                                value={answerToText(item)}
                                fullWidth
                                label={item.question.text}
                              />
                            </Grid>
                          );
                        }

                        if (dropdownInputTypes.includes(item.elementTypeCode)) {
                          return (
                            <Grid
                              key={item.question._id}
                              item
                              xs={12}
                              sm={12}
                              md={6}
                            >
                              <OutlinedInput
                                disabled={true}
                                value={answerFromSelectToText(item)}
                                fullWidth
                                label={item.question.text}
                              />
                            </Grid>
                          );
                        }

                        if (
                          item.elementTypeCode === "image-upload" &&
                          !item.question.element.allowMultiple
                        ) {
                          return (
                            <Grid
                              key={item.question._id}
                              item
                              xs={12}
                              sm={12}
                              md={6}
                            >
                              <ImageQuestionInput data={item} />
                            </Grid>
                          );
                        }

                        if (
                          item.elementTypeCode === "image-upload" &&
                          item.question.element.allowMultiple
                        ) {
                          return (
                            <Grid
                              key={item.question._id}
                              item
                              xs={12}
                              sm={12}
                              md={12}
                            >
                              <MultiImageQuestionInput data={item} />
                            </Grid>
                          );
                        }

                        if (item.elementTypeCode === "additional-load") {
                          return (
                            <Grid
                              key={item.question._id}
                              item
                              xs={12}
                              sm={12}
                              md={6}
                            >
                              <AdditionalLoadQuestionInput data={item} />
                            </Grid>
                          );
                        }

                        return null;
                      })}
                    </Grid>
                  </div>
                );
              })}
              {Object.keys(powerRequirements).length > 0 && (
                <div className={classes.sectionContainer}>
                  <h2 className={classes.sectionHeading}>Power Requirements</h2>
                  <Grid container spacing={2}>
                    {Object.keys(powerRequirements).map((item, i) => {
                      return (
                        <Grid key={i} item xs={12} sm={12} md={6}>
                          <OutlinedInput
                            disabled={true}
                            value={powerRequirements[item]}
                            fullWidth
                            label={`${item} - Estimated Power Req (W)`}
                          />
                        </Grid>
                      );
                    })}
                    <Grid item xs={12} sm={12} md={6}>
                      <OutlinedInput
                        disabled={true}
                        value={Object.values(powerRequirements).reduce(
                          (acc, item) => acc + item
                        )}
                        fullWidth
                        label="Subtotal"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                      <OutlinedInput
                        disabled={true}
                        value={tier?.name}
                        fullWidth
                        label="Tier according to total consumption"
                      />
                    </Grid>
                  </Grid>
                </div>
              )}
            </div>
          </div>
        </AppFrame>
      </div>
      <MessageModal data={message} handleClose={handleCloseModal} />
    </div>
  );
};

export default CustomerAnswers;
