import React, { useState, useEffect } from "react";
import { Modal, Fade } from "@material-ui/core";
import useStyles from "./style";
import Button from "../Button";

const AdditionalLoadQuestionInput = (props) => {
  const classes = useStyles();
  const [loads, setLoads] = useState(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);

  useEffect(() => {
    const answers = props.data.answers;

    const d = answers.map((item) => {
      const loadAnswers = item.answer.split("||");

      const a = loadAnswers.reduce((acc, item2) => {
        const data = item2.split(":");
        acc[data[0]] = data[1];
        return acc;
      }, {});

      return a;
    });

    setLoads(d);
  }, [props.data]);

  if (!loads) return null;

  const openDetailsModal = () => {
    setIsDetailsModalOpen(true);
  };
  const closeDetailsModal = () => {
    setIsDetailsModalOpen(false);
  };

  return (
    <div>
      <p className={classes.label}>{props.data.question.text}</p>
      <div className={classes.loadContainer}>
        <p className={classes.loadLabel}>
          Total Load: {loads.reduce((acc, item) => acc + Number(item.watt), 0)}
        </p>
        <p onClick={openDetailsModal} className={classes.loadLink}>
          View details
        </p>
      </div>
      {isDetailsModalOpen && (
        <LoadModal
          onClose={closeDetailsModal}
          data={loads}
          open={isDetailsModalOpen}
        />
      )}
    </div>
  );
};

const LoadModal = (props) => {
  const classes = useStyles();

  const handleClick = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <Modal
      className={classes.modalContainer}
      open={props.open}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade timeout={{ appear: 0, enter: 500 }} in={props.open}>
        <div className={classes.paper}>
          <div className={classes.listContainer}>
            <div className={classes.listHeadingItem}>
              <div className={classes.listHeadingLeft}>Name</div>
              <div className={classes.listHeadingRight}>Watt</div>
            </div>
            <div className={classes.listItemContainer}>
              {props.data.map((item, index) => (
                <div key={index} className={classes.listItem}>
                  <div className={classes.listLeft}>{item.name}</div>
                  <div className={classes.listRight}>{item.watt}</div>
                </div>
              ))}
            </div>
          </div>
          <div className={classes.buttonContainer}>
            <Button onClick={handleClick} title="Close" theme="danger" />
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default AdditionalLoadQuestionInput;
