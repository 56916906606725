import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router";
import moment from "moment";
import AddIcon from "@material-ui/icons/AddCircle";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { useTheme } from "@material-ui/core/styles";

import editIcon from "../../assets/icons/edit.png";
import useStyles from "./style";
import MessageModal from "../../components/MessageModal";
import AppFrame from "../../components/AppFrame";
import AppHeader from "../../components/AppHeader";
import Loader from "../../components/Loader";
import api from "../../utils/api";

const SubCategories = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState({});
  const [subCategories, setSubCategories] = useState([]);

  const columns = [
    {
      name: "Sr.No",
      selector: (row) => row.serialNo,
      sortable: true,
      width: "130px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      wrap: true,
      sortable: true,
    },
    {
      name: "Added On",
      selector: (row) => row.createdAt,
      sortable: true,
      width: "250px",
    },
    {
      name: "Last Updated On",
      selector: (row) => row.updatedAt,
      sortable: true,
      width: "250px",
    },
    {
      name: "Actions",
      width: "100px",
      cell: (row) => {
        return (
          <div>
            <img
              style={{ width: 25, cursor:"pointer" }}
              onClick={() => {
                navigateToAddSubCategories(row);
              }}
              src={editIcon}
              alt="edit icon"
            />
            <DeleteForeverIcon
              onClick={() => {
                deleteSubCategory(row);
              }}
              style={{ color: theme.palette.iconBlue,  cursor:"pointer" }}
            />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    fetchSubCategories();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchSubCategories = async () => {
    try {
      let i;
      setLoading(true);

      const response = await api.fetchSubCategories();

      for (i = 0; i < response.data.questionSubCategories.length; i++) {
        response.data.questionSubCategories[i].serialNo = i + 1;
        response.data.questionSubCategories[i].createdAt = moment(
          response.data.questionSubCategories[i].createdAt
        ).format("MM/DD/YYYY hh:mm a");
        response.data.questionSubCategories[i].updatedAt = moment(
          response.data.questionSubCategories[i].updatedAt
        ).format("MM/DD/YYYY hh:mm a");
      }

      setSubCategories(response.data.questionSubCategories);
    } catch (e) {
      setMessage({
        isError: true,
        title: "Error",
        message: e.message,
      });
    }

    setLoading(false);
  };

  const deleteSubCategory = async (value) => {
    try {
      let i;
      setLoading(true);

      const payload = {
        _id: value._id,
      };

      await api.deleteSubCategories(payload);

      for (i = 0; i < subCategories.length; i++) {
        if (value._id === subCategories[i]._id) {
          subCategories.splice(i, 1);
          break;
        }
      }
    } catch (e) {
      setMessage({
        isError: true,
        title: "Error",
        message: e.message,
      });
    }

    setLoading(false);
  };

  const navigateToAddSubCategories = (value) => {
    history.push({
      pathname: "/add-sub-categories",
      value,
    });
  };

  const handleCloseModal = () => {
    setMessage({});
  };

  return (
    <div className={classes.mainContainer}>
      {loading && <Loader />}
      <MessageModal data={message} handleClose={handleCloseModal} />
      <div className={classes.container}>
        <AppFrame>
          <div className={classes.contentContainer}>
            <AppHeader />
            <div className={classes.heading}>
              <h1>SUB CATEGORIES </h1>
              <AddIcon
                className={classes.addIcon}
                onClick={() => {
                  navigateToAddSubCategories();
                }}
              />
            </div>
            <div className={classes.tableContainer}>
              <DataTable columns={columns} data={subCategories} />
            </div>
          </div>
        </AppFrame>
      </div>
    </div>
  );
};

export default SubCategories;
