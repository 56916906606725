import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.white,
    padding: theme.spacing(2),
    outline: "none",
    position: "relative",
    overflow: "hidden",
    minWidth: 330,
    minHeight: 150,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "90%",
    height: "50%",
    maxWidth: 500,
    maxHeight: 220,
    borderRadius: 10,
  },
  circle: {
    width: 110,
    height: 110,
    position: "absolute",
    top: -40,
    left: -40,
    backgroundColor: theme.palette.green,
    transform: "rotateX(20deg)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 55,
  },
  circleIcon: {
    marginTop: "28px",
    marginLeft: "30px",
    width: "45px",
    height: "45px",
  },
  titleBox: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    fontSize: "2.3em",
  },
  borderLine: {
    border: `1px solid ${theme.palette.green}`,
    width: "15%",
  },
  messageBox: {
    display: "flex",
    justifyContent: "center",
  },
  message: {
    marginTop: theme.spacing(1.5),
    padding: theme.spacing(0, 3, 0, 3),
    fontSize: "1.2em",
  },
  actionTextSuccess: {
    textAlign: "center",
    padding: theme.spacing(1.5, 0, 1.5, 0),
    color: theme.palette.green,
    fontWeight: "bold",
    fontSize: "1.2em",
    cursor: "pointer",
  },
  actionTextError: {
    textAlign: "center",
    padding: theme.spacing(1.5, 0, 1.5, 0),
    color: theme.palette.red,
    fontWeight: "bold",
    fontSize: "1.2em",
    cursor: "pointer",
  },
  success: {
    backgroundColor: theme.palette.green,
    borderColor: theme.palette.green,
  },
  error: {
    backgroundColor: theme.palette.red,
    borderColor: theme.palette.red,
  },
}));

export default useStyles;
