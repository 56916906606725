export const SET_USER_DATA = 'SET_USER_DATA';
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA';

const action = {};

action.setUserData = function (payload) {
  return {
    type: SET_USER_DATA,
    payload,
  };
};

action.clearUserData = function () {
  return {
    type: CLEAR_USER_DATA,
  };
};

export default action;