import { useRef } from "react";

import constants from "../../config/constants";
import globalHelpers from "../../utils/globalHelpers";
import useStyles, { styles } from "./style";
import CameraIcon from "../../assets/icons/camera.png";
import Image from "../Image";

const Avatar = (props) => {
  const formEl = useRef(null);
  const classes = useStyles();

  const handleImageChange = async (evt) => {
    const file = evt.target.files[0];

    if (file) {
      const base64 = await globalHelpers.blobToBase64(file);

      if (props.onAvatarChange) {
        props.onAvatarChange(
          {
            name: file.name,
            size: file.size,
            type: file.type,
            base64,
          },
          file
        );
      }

      formEl.current.reset();
    }
  };

  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  const getAvatarSource = () => {
    if (
      typeof props.source === "string" &&
      props.source.startsWith("uploads/")
    ) {
      return constants.BASE_URL_ROOT + "/" + props.source;
    }

    return props.source;
  };

  return (
    <>
      {props.editable && !props.onChange && (
        <form ref={formEl} style={{ display: "none" }}>
          <input
            onChange={handleImageChange}
            type="file"
            id="avatar-picker"
            style={{ display: "none" }}
          />
        </form>
      )}
      <div
        onClick={handleClick}
        style={{ width: props.size, height: props.size }}
        className={classes.container}
      >
        <Image style={styles.avatar} src={getAvatarSource()} />
        {props.editable && (
          <div className={`${classes.cameraIconContainer} box-shadow-dark`}>
            <img alt="camera" className={classes.cameraIcon} src={CameraIcon} />
          </div>
        )}
        <label
          onClick={(e) => e.stopPropagation()}
          className={classes.labelContainer}
          htmlFor="avatar-picker"
        ></label>
      </div>
    </>
  );
};

Avatar.defaultProps = {
  size: "60px",
  source: null,
  editable: false,
};

export default Avatar;
