import { Route } from "react-router-dom";

import withSecureRoute from "./withSecureRoute";

const SecureRoute = ({ component: Screen, ...props }) => {
  const SecureScreen = withSecureRoute(Screen, props);

  return <Route component={SecureScreen} {...props} />;
};

export default SecureRoute;
