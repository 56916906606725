import React, { useState, useEffect } from "react";

import Image from "../Image";

const loaderContainerStyles = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};

const labelStyles = {
  fontSize: "16px",
  fontWeight: 500,
};

const imageMainContainerStyles = {
  width: "80px",
  height: "80px",
  border: "0.5px solid rgba(0,0,0,0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const imageStyle = {
  width: "100%",
  height: "100%",
  resizeMode: "cover",
};

const ImageQuestionInput = (props) => {
  const [image, setImage] = useState(null);

  useEffect(() => {
    const data = props.data;

    if (
      data.answers &&
      Array.isArray(data.answers) &&
      data.answers[0] &&
      data.answers[0].answer
    ) {
      setImage(data.answers[0].answer);
    }
  }, [props.src]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ ...props.style, ...loaderContainerStyles }}>
      <p style={labelStyles}>{props.data.question.text}</p>
      <div style={imageMainContainerStyles}>
        {image && <Image allowExpand={true} style={imageStyle} src={image} />}
      </div>
    </div>
  );
};

export default ImageQuestionInput;
