import React from "react";
import useStyles from "./style.js";

const FloatingButton = (props) => {
  const { onClick } = props
  const classes = useStyles();
  return (
    <div className={classes.container} onClick={onClick}>
        <p>Add</p>
    </div>
  );
};

export default FloatingButton;
