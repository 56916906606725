import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "row",
    borderRadius: "40px",
  },
  leftContainer: {
    width: 55 + 40,
    height: "100%",
    background: `linear-gradient(${theme.palette.lightOrange}, ${theme.palette.orange});`,
    borderTopLeftRadius: "40px",
    borderBottomLeftRadius: "40px",
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    paddingRight: "40px",
  },
  rightContainer: {
    flex: 1,
    background: theme.palette.white,
    borderRadius: "40px",
    marginLeft: "-40px",
    overflow: "hidden",
  },
  dashboardIconContainer: {
    backgroundColor: theme.palette.white,
    width: "45px",
    height: "45px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  dashboardIcon: {
    width: "50%",
    height: "50%",
    cursor: "pointer",
  },
  nameContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    flex: 1,
  },
  wordContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  },
  nameLetter: {
    fontSize: "1em",
    color: theme.palette.white,
    fontWeight: "bold",
    textAlign: "center",
  },
  firstIconContainer: {
    marginBottom: theme.spacing(3),
  },
  iconContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  icon: {
    width: "22px",
    height: "22px",
    cursor: "pointer",
  },
}));

export default useStyles;
