import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.background,
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  container: {
    width: "100%",
    height: "100%",
    padding: "30px 30px",
  },
  frameContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentContainer: {
    backgroundColor: "white",
    width: "100%",
    padding: theme.spacing(3),
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    overflowY: "auto",
  },

  title: {
    fontSize: "2.4em",
  },
  form: {
    width: "100%",
    maxWidth: 450,
    marginTop: theme.spacing(3),
  },
  buttonContainer: {
    marginTop: theme.spacing(3),
  },
  seperator: {
    marginTop: theme.spacing(2),
  },
}));

export default useStyles;
