import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    right: "-2px",
    bottom: "50px",
    position: "absolute",
    padding: "8px",
    backgroundColor: theme.palette.lightOrange,
    width: "100px",
    textAlign: "center",
    fontSize: "1.4em",
    borderTopLeftRadius: "15px",
    borderBottomLeftRadius: "15px",
    color: theme.palette.white,
    fontWeight: "bold",
    cursor: "pointer",
    zIndex: 1,
  },
}));

export default useStyles;
