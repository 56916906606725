import axios from "axios";

const axiosUtils = {};

axiosUtils.post = async function (url, body) {
  try {
    const response = await axios.post(url, body);
    return response.data;
  } catch (e) {
    const message = e?.response?.data?.message || e?.message;
    throw new Error(message);
  }
};

axiosUtils.patch = async function (url, body) {
  try {
    const response = await axios.patch(url, body);
    return response.data;
  } catch (e) {
    const message = e?.response?.data?.message || e?.message;
    throw new Error(message);
  }
};

axiosUtils.delete = async function (url, body) {
  try {
    const response = await axios.delete(url, { data: body });
    return response.data;
  } catch (e) {
    const message = e?.response?.data?.message || e?.message;
    throw new Error(message);
  }
};

axiosUtils.get = async function (url, params) {
  try {
    const response = await axios.get(url, { params });
    return response.data;
  } catch (e) {
    const message = e?.response?.data?.message || e?.message;
    throw new Error(message);
  }
};

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

axiosUtils.downloadImage = async function (url, params) {
  try {
    const response = await axios.get(url, { params, responseType: "blob" });
    const base64 = await blobToBase64(response.data);
    return base64;
  } catch (e) {
    const message = e?.response?.data?.message || e?.message;
    throw new Error(message);
  }
};

export default axiosUtils;
