import React from "react";
import useStyles from "./style.js";

import loaderIcon from "../../assets/icons/loader.png";

const Spinner = (props) => {
  const classes = useStyles();
  return (
    <img
      alt="spinner"
      width={props.size}
      height={props.size}
      src={loaderIcon}
      className={classes.loader}
    />
  );
};

Spinner.defaultProps = {
  size: "30px",
};

export default Spinner;
