import Ink from "react-ink";

import RightArrow from "../../assets/icons/right-arrow.png";
import useStyles from "./style";

const RightArrowButton = (props) => {
  const { onClick } = props
  const classes = useStyles();

  return (
    <div className={classes.container} onClick={onClick}>
      <img alt="right-arrow" src={RightArrow} className={classes.icon} />
      <Ink />
    </div>
  );
};

export default RightArrowButton;
