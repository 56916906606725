import React, { useEffect, useRef, useState } from "react";

import colors from "../../config/colors";
import Spinner from "../../components/Spinner";
import ColoredBox from "../ColoredBox";
import Chart from "./Chart";
import useStyles from "./style";

const PieChart = (props) => {
  const classes = useStyles();
  const chartContainerRef = useRef();
  const [width, setWidth] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (chartContainerRef.current) {
      const offsetHeight = chartContainerRef.current.offsetHeight;
      const height = offsetHeight >= 250 ? 250 : offsetHeight - 16;
      setWidth(height);
    }
  }, [chartContainerRef]);

  useEffect(() => {
    if (props.data && Object.keys(props.data).length) {
      const newData = [
        {
          title: "Active",
          value: Math.round((props.data.active / props.data.total) * 100),
          color: colors.SECONDARY,
        },
        {
          title: "In-Active",
          value: (props.data.inactive / props.data.total) * 100,
          color: colors.PRIMARY,
        },
        {
          title: "New",
          value: Math.round((props.data.new / props.data.total) * 100),
          color: colors.THEME_BLUE,
        },
      ];

      if (!props.data.active) {
        const index = newData.findIndex((item) => item.title === "Active");
        newData.splice(index, 1);
      }

      if (!props.data.inactive) {
        const index = newData.findIndex((item) => item.title === "In-Active");
        newData.splice(index, 1);
      }

      if (!props.data.new) {
        const index = newData.findIndex((item) => item.title === "New");
        newData.splice(index, 1);
      }

      setData(newData);
    }
  }, [props.data]);

  return (
    <div className={classes.container}>
      {props.loading ? (
        <div className={classes.loadingContainer}>
          <Spinner size="50px" />
        </div>
      ) : (
        <>
          <div className={classes.chartHeadingContainer}>
            <span className={classes.chartHeading}>Customer Status</span>
          </div>
          <div className={classes.chartContainer}>
            <div className={classes.chartInfoContainer}>
              <div className={classes.coloredBoxContainer}>
                <ColoredBox color={colors.THEME_BLUE} size="15px">
                  New
                </ColoredBox>
              </div>
              <div className={classes.coloredBoxContainer}>
                <ColoredBox color={colors.SECONDARY} size="15px">
                  Active
                </ColoredBox>
              </div>
              <div className={classes.coloredBoxContainer}>
                <ColoredBox color={colors.PRIMARY} size="15px">
                  In-Active
                </ColoredBox>
              </div>
            </div>
            <div ref={chartContainerRef} style={{ width: width }}>
              {!!width && <Chart data={data} />}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PieChart;
