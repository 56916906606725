import React, { useState, useEffect } from "react";

import Spinner from "../Spinner";
import SelectInput from "../SelectInput";
import ColoredBox from "../ColoredBox";
import useStyles from "./style";
import Chart from "./Chart";
import colors from "../../config/colors";
import constants from "../../config/constants";

const BarChart = (props) => {
  const [data, setData] = useState([]);
  const [xaxis, setXaxis] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    if (props.data && props.data.length) {
      const newXaxis = props.data.map((item) => {
        if (props.selectedMonth === "all") {
          return constants.MONTHS_SHORT[item.label - 1];
        }

        return item.label;
      });

      const newData = [
        {
          name: "New",
          data: props.data.map((item) => item.new),
        },
        {
          name: "Active",
          data: props.data.map((item) => item.active),
        },
        {
          name: "In-Active",
          data: props.data.map((item) => item.inactive),
        },
      ];

      setData(newData);
      setXaxis(newXaxis);
    }
  }, [props.data, props.selectedMonth.value]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleYearChange = (newYear) => {
    props.onYearChange(newYear);
  };

  const handleMonthChange = (newMonth) => {
    props.onMonthChange(newMonth);
  };

  return (
    <div className={classes.container}>
      {props.loading ? (
        <div className={classes.loadingContainer}>
          <Spinner size="60px" />
        </div>
      ) : (
        <>
          <div className={classes.chartHeadingContainer}>
            <span className={classes.chartHeading}>Customer Retention</span>
            <div className={classes.insideHeaderRightContainer}>
              <div className={classes.selectInput}>
                <SelectInput
                  placeholder="Select year"
                  style={{ paddingTop: 10, paddingBottom: 10 }}
                  options={props.allYears}
                  value={props.selectedYear}
                  onChange={handleYearChange}
                />
              </div>
              <div className={classes.selectInput}>
                <SelectInput
                  placeholder="Select month"
                  style={{ paddingTop: 10, paddingBottom: 10 }}
                  options={constants.MONTHS_FOR_CHART}
                  value={props.selectedMonth}
                  onChange={handleMonthChange}
                />
              </div>
            </div>
          </div>
          <div className={classes.chartContainer}>
            {xaxis.length && <Chart data={data} xaxis={xaxis} />}
          </div>
          <div className={classes.chartInfoContainer}>
            <div className={classes.chartInfoLeftContainer}>
              <ColoredBox color={colors.THEME_BLUE} size="15px">
                New
              </ColoredBox>
              <ColoredBox color={colors.SECONDARY} size="15px">
                Active
              </ColoredBox>
              <ColoredBox color={colors.PRIMARY} size="15px">
                In-Active
              </ColoredBox>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

BarChart.defaultProps = {
  allYears: [],
};

export default BarChart;
