import constants from "../config/constants";
import axios from "./axios";

const api = {};

api.login = function (body) {
  const url = `${constants.BASE_URL}/account-management/user/login`;
  return axios.post(url, body);
};

api.forgotPassword = function (body) {
  const url = `${constants.BASE_URL}/account-management/user/forgot-password`;
  return axios.post(url, body);
};

api.fetchCustomerList = function (body) {
  const url = `${constants.BASE_URL}/account-management/user`;
  return axios.get(url, body);
};

api.verifyOTP = function (body) {
  const url = `${constants.BASE_URL}/account-management/user/verify-otp`;
  return axios.post(url, body);
};

api.resendOTP = function (body) {
  const url = `${constants.BASE_URL}/account-management/user/resend-otp`;
  return axios.post(url, body);
};

api.resetPassword = function (body) {
  const url = `${constants.BASE_URL}/account-management/user/reset-password`;
  return axios.post(url, body);
};

api.fetchCustomerStatusOverview = function (body) {
  const url = `${constants.BASE_URL}/onboarding/analytics/customer-status/overview`;
  return axios.get(url, body);
};

api.fetchCustomerRetention = function (body) {
  const url = `${constants.BASE_URL}/onboarding/analytics/customer-retention`;
  return axios.get(url, body);
};

api.fetchCashReceivable = function (body) {
  const url = `${constants.BASE_URL}/onboarding/analytics/cash-receivable`;
  return axios.get(url, body);
};

api.fetchCashReceived = function (body) {
  const url = `${constants.BASE_URL}/account-management/user/cash-received`;
  return axios.get(url, body);
};

api.fetchCashPending = function (body) {
  const url = `${constants.BASE_URL}/account-management/user/cash-pending`;
  return axios.get(url, body);
};

api.getStartingYearOfLeads = function (body) {
  const url = `${constants.BASE_URL}/onboarding/analytics/lead/starting-year`;
  return axios.get(url, body);
};

api.changePassword = function (body) {
  const url = `${constants.BASE_URL}/account-management/user/change-password`;
  return axios.patch(url, body);
};

api.changeCustomerStatus = function (body) {
  const url = `${constants.BASE_URL}/onboarding/customer/status`;
  return axios.patch(url, body);
};
api.editProfile = function (body) {
  const url = `${constants.BASE_URL}/account-management/user`;
  return axios.patch(url, body);
};

api.addUser = function (body) {
  const url = `${constants.BASE_URL}/account-management/user/add`;
  return axios.post(url, body);
};

api.deleteUser = function (body) {
  const url = `${constants.BASE_URL}/account-management/user`;
  return axios.delete(url, body);
};

api.uploadDocument = function (body) {
  const url = `${constants.BASE_URL}/onboarding/customer/upload-documents`;
  return axios.post(url, body);
};

api.fetchQuestions = function (body) {
  const url = `${constants.BASE_URL}/onboarding/questionnaire`;
  return axios.get(url, body);
};

api.getCategory = function (body) {
  const url = `${constants.BASE_URL}/onboarding/question-category`;
  return axios.get(url, body);
};

api.getSubCategory = function (body) {
  const url = `${constants.BASE_URL}/onboarding/question-sub-category`;
  return axios.get(url, body);
};

api.getElementTypes = function (body) {
  const url = `${constants.BASE_URL}/onboarding/element-type`;
  return axios.get(url, body);
};

api.getApplianceTypes = function (body) {
  const url = `${constants.BASE_URL}/onboarding/appliance-type`;
  return axios.get(url, body);
};

api.createQuestion = function (body) {
  const url = `${constants.BASE_URL}/onboarding/question`;
  return axios.post(url, body);
};

api.editQuestion = function (body) {
  const url = `${constants.BASE_URL}/onboarding/question`;
  return axios.patch(url, body);
};

api.deleteQuestion = function (body) {
  const url = `${constants.BASE_URL}/onboarding/question`;
  return axios.delete(url, body);
};

api.fetchSubCategories = function (body) {
  const url = `${constants.BASE_URL}/onboarding/question-sub-category`;
  return axios.get(url, body);
};

api.createSubCategories = function (body) {
  const url = `${constants.BASE_URL}/onboarding/question-sub-category`;
  return axios.post(url, body);
};

api.editSubCategories = function (body) {
  const url = `${constants.BASE_URL}/onboarding/question-sub-category`;
  return axios.patch(url, body);
};

api.deleteSubCategories = function (body) {
  const url = `${constants.BASE_URL}/onboarding/question-sub-category`;
  return axios.delete(url, body);
};

api.uploadImage = function (body) {
  const url = `${constants.BASE_URL}/media/image-upload`;
  return axios.post(url, body);
};

api.downloadImage = function (body) {
  const url = `${constants.BASE_URL}/media/image`;
  return axios.get(url, body);
};

api.getCustomerById = function (body) {
  const url = `${constants.BASE_URL}/onboarding/customer/byid`;
  return axios.get(url, body);
};

api.getAllCustomers = function (body) {
  const url = `${constants.BASE_URL}/onboarding/customer/all`;
  return axios.get(url, body);
};

export default api;
