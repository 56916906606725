import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import authAction from "../../redux/auth/action";
import api from "../../utils/api";
import Loader from "../../components/Loader";
import MessageModal from "../../components/MessageModal";
import OutlinedInput from "../../components/OutlinedInput";
import Button from "../../components/Button";
import AppFrame from "../../components/AppFrame";
import useStyles from "./style";

const ChangePassword = (props) => {
  const classes = useStyles();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const validationErrors = validation();

      if (validationErrors) {
        return;
      }

      setLoading(true);

      const payload = {
        currentPassword,
        newPassword,
      };

      await api.changePassword(payload);

      resetFields();
      if (!props.user.changedFirstPassword) {
        setLoading(false);

        return setMessage({
          isError: false,
          title: "Success",
          message:
            "Password has been changed successfully. Please re-login to your account.",
          onConfirm: () => {
            props.setUser({ user: null });
            history.push("/");
          },
        });
      }

      setMessage({
        isError: false,
        title: "Success",
        message: "Succcessful",
        onConfirm: () => {
          history.push("/dashboard");
        },
      });

      props.setUser({ user: { ...props.user, changedFirstPassword: true } });
    } catch (e) {
      setMessage({
        isError: true,
        title: "Error",
        message: e?.message,
      });
    }

    setLoading(false);
  };

  const resetFields = () => {
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  const handleCloseModal = () => {
    setMessage({});
  };

  const handleChange = (label, value) => {
    if (label === "currentPassword") {
      setCurrentPassword(value);
    }

    if (label === "newPassword") {
      setNewPassword(value);
    }

    if (label === "confirmPassword") {
      setConfirmPassword(value);
    }
  };

  const validation = () => {
    const errors = {};

    if (!currentPassword) {
      errors.currentPassword = "Please enter your current password";
    }

    if (!newPassword) {
      errors.newPassword = "Please enter your new Password";
    }

    if (!confirmPassword) {
      errors.confirmPassword = "Please confirm your new password";
    }

    if (!Object.keys(errors).length && newPassword !== confirmPassword) {
      errors.newPassword = "Password doesn't match";
      errors.confirmPassword = "Password doesn't match";
    }

    setErrors(errors);
    return Object.keys(errors).length;
  };

  return (
    <>
      <div className={classes.mainContainer}>
        <div className={classes.container}>
          <AppFrame name="password">
            <div className={classes.frameContainer}>
              <div className={classes.contentContainer}>
                <span className={classes.title}>Change Password</span>
                <form onSubmit={handleSubmit} className={classes.form}>
                  <OutlinedInput
                    autoFocus={true}
                    label="Current Password"
                    type="password"
                    inputType="password"
                    errorMessage={errors.currentPassword}
                    value={currentPassword}
                    fullWidth
                    onChange={(e) =>
                      handleChange("currentPassword", e.target.value)
                    }
                  />
                  <div className={classes.seperator}></div>
                  <OutlinedInput
                    label="New Password"
                    type="password"
                    inputType="password"
                    errorMessage={errors.newPassword}
                    value={newPassword}
                    fullWidth
                    onChange={(e) =>
                      handleChange("newPassword", e.target.value)
                    }
                  />
                  <div className={classes.seperator}></div>
                  <OutlinedInput
                    label="Confirm Password"
                    type="password"
                    inputType="password"
                    errorMessage={errors.confirmPassword}
                    value={confirmPassword}
                    fullWidth
                    onChange={(e) =>
                      handleChange("confirmPassword", e.target.value)
                    }
                  />
                  <div className={classes.buttonContainer}>
                    <Button
                      type="submit"
                      title="Change password"
                      theme="primary"
                    />
                  </div>
                </form>
              </div>
            </div>
          </AppFrame>
        </div>
      </div>
      {loading && <Loader />}
      <MessageModal data={message} handleClose={handleCloseModal} />
    </>
  );
};

const mapStateToProps = (store) => ({
  user: store.auth.user,
});

const mapDispatchToProps = {
  setUser: authAction.setUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
