import numeral from "numeral";
import moment from "moment";

const globalHelpers = {};

globalHelpers.formatCurrency = (num) => {
  return numeral(num).format("0,00.00");
};

globalHelpers.generateYearRange = (startingYear = new Date().getFullYear()) => {
  const currentMoment = moment().startOf("year");
  let currentYear = Number(startingYear);
  const yearMoment = moment().year(currentYear).startOf("year");
  const diff = currentMoment.diff(yearMoment, "years");

  const years = [];
  for (let i = 0; i <= diff; i++) {
    years.push({ label: currentYear.toString(), value: currentYear });
    currentYear += 1;
  }

  return years;
};

globalHelpers.blobToBase64 = (blob) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

globalHelpers.isBlankString = (str = "") => {
  return !str.trim().length;
};

globalHelpers.isCloudnaryURL = (url = "") => {
  return (
    (url.startsWith("http") || url.startsWith("https")) &&
    url.includes("cloudinary.com")
  );
};

globalHelpers.replaceSpaceWithDash = (text = "") => {
  return text.trim().replace(/ /g, "-").toLowerCase();
};

globalHelpers.capitalizeFirstLetter = (string = "") => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export default globalHelpers;
