import ApexChart from "react-apexcharts";
import colors from "../../../config/colors";

const Chart = (props) => {
  const OPTIONS = {
    chart: {
      id: "basic-bar",
      stacked: false,
      redrawOnParentResize: true,
      redrawOnWindowResize: true,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: false,
      },
    },
    colors: [colors.THEME_BLUE, colors.SECONDARY, colors.PRIMARY],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "60%",
        endingShape: "rounded",
      },
    },

    stroke: {
      show: true,
      width: 4,
      colors: ["transparent"],
    },
    xaxis: {
      floating: false,
      categories: props.xaxis,
      labels: {
        rotate: -45,
        rotateAlways: false,
        style: {
          colors: colors.DARKGREY,
          fontSize: "11px",
          fontFamily: "Roboto",
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: colors.DARKGREY,
          fontSize: "11px",
          fontFamily: "Roboto",
          fontWeight: 400,
        },
        offsetX: 0,
        offsetY: 0,
        rotate: 0,
        formatter: (value) => {
          return value;
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 1200,
        options: {
          xaxis: {
            labels: {
              style: {
                colors: colors.DARKGREY,
                fontSize: "9px",
                fontFamily: "Roboto",
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                colors: colors.DARKGREY,
                fontSize: "9px",
                fontFamily: "Roboto",
              },
            },
          },
        },
      },
    ],
  };

  const SERIES = props.data;

  return (
    <ApexChart
      options={OPTIONS}
      series={SERIES}
      type="bar"
      width="100%"
      height="100%"
    />
  );
};

Chart.defaultProps = {
  xaxis: [],
  data: [],
};

export default Chart;
