import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

const withSecureRoute = (WrappedComponent, props) => {
  return () => {
    const user = useSelector((state) => state.auth.user);

    if (
      user &&
      !user.changedFirstPassword &&
      !props.allowWithoutChangingFirstPassword
    ) {
      if (props.showErrorDialog) {
        props.showErrorDialog(
          "Denied",
          "Please change your password to continue using the app"
        );
      }

      return <Redirect to="/change-password" />;
    }

    if (user) {
      return <WrappedComponent />;
    }

    if (props.showErrorDialog) {
      props.showErrorDialog(
        "Unauthenticated",
        "You should be logged in to access this page"
      );
    }

    return <Redirect to="/" />;
  };
};

export default withSecureRoute;
