import React, { useState, useEffect } from "react";
import ReactCodeInput from "react-code-input";
import { useHistory } from "react-router";

import api from "../../utils/api";
import Loader from "../../components/Loader";
import MessageModal from "../../components/MessageModal";
import colors from "../../config/colors";
import BackButton from "../../components/BackButton";
import TextButton from "../../components/TextButton";
import useStyles, { codeInputStyles } from "./style";

const OTPVerify = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    isError: false,
    title: "",
    message: "",
  });
  const classes = useStyles();
  const history = useHistory();
  const navigationParams = history?.location?.state || {};
  const [codeInputKey, setCodeInputKey] = useState(0);

  useEffect(() => {
    if (!navigationParams.email) {
      history.replace("/forgot-password");
    }
  }, [history, navigationParams.email]);

  const handleChange = (code) => {
    if (code.length === 6) {
      verifyOTP(code);
    }
  };

  const verifyOTP = async (code) => {
    try {
      setLoading(true);

      const payload = {
        email: navigationParams.email,
        otp: code,
      };

      await api.verifyOTP(payload);

      history.replace("/reset-password", { email: navigationParams.email });
    } catch (e) {
      setMessage({
        isError: true,
        title: "Error",
        message: e?.message,
      });
      setCodeInputKey(codeInputKey + 1);
    }

    setLoading(false);
  };

  const handleCloseModal = () => {
    setMessage({
      isError: false,
      title: "",
      message: "",
    });
  };

  const resendPin = async () => {
    try {
      setLoading(true);

      const payload = {
        email: navigationParams.email,
      };

      await api.resendOTP(payload);
      setMessage({
        isError: false,
        title: "Success",
        message: "OTP successfully sent to your email",
      });
    } catch (e) {
      setMessage({
        isError: true,
        title: "Error",
        message: e?.message,
      });
    }

    setLoading(false);
  };

  return (
    <>
      <div className={classes.mainContainer}>
        <div className={`${classes.container} box-shadow`}>
          <div className={classes.headerContainer}>
            <BackButton />
          </div>
          <div className={classes.contentContainer}>
            <p className={classes.title}>Please enter 6 digit pin</p>
            <div className={classes.codeInputContainer}>
              <ReactCodeInput
                key={codeInputKey}
                onChange={handleChange}
                autoFocus={true}
                inputStyle={codeInputStyles}
                type="password"
                fields={6}
              />
            </div>
            <p className={classes.instruction}>
              Didn't receive code?{" "}
              <TextButton onClick={resendPin} style={{ color: colors.ORANGE }}>
                Resend pin
              </TextButton>
            </p>
          </div>
        </div>
      </div>
      {loading && <Loader />}
      <MessageModal data={message} handleClose={handleCloseModal} />
    </>
  );
};

export default OTPVerify;
