import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: "flex",
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(1),
    height: "100%",
    alignItems: "center",
    flexDirection: "column",
  },
  image: {
    height: "100%",
  },
  modalHeaderContainer: {
    height: "40px",
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  modalCrossIcon: {
    width: "40px",
    height: "40px",
    cursor: "pointer"
  },
  modalContentContainer: {
    flex: 1,
    width: "100%",
    paddingTop: theme.spacing(2),
  },
  boxContainer: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
  },
 
}));

export default useStyles;
