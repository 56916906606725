import { makeStyles } from "@material-ui/core/styles";
import colors from "../../config/colors";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.background,
    height: "100%",
  },
  container: {
    width: "100%",
    height: "100%",
    padding: "30px 30px",
  },
  contentContainer: {
    padding: theme.spacing(3),
    position: "relative",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  tabBarSection: {
    display: "flex",
    marginTop: "20px",
    alignItems: "center",
    "& p": {
      fontSize: "1.2em",
      width: "80px",
      textAlign: "center",
    },
  },
  topSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "23px",
  },
  leftSection: {
    display: "flex",
    alignItems: "center",
  },
  profilePicBox: {
    width: "80px",
  },
  profilePicture: {
    width: "70px",
    height: "70px",
    borderRadius: "35px",
  },
  userName: {
    fontSize: "1.4em",
  },
  welcomeText: {
    fontSize: "2.6em",
    fontWeight: "bold",
  },
  logo: {
    backgroundImage: "url('../../assets/images/soladLogo.png')",
    // backgroundImage: `url(${"../../assets/images/soladLogo.png"})`,
    width: "100px",
    height: "100px",
  },
  tableContainer: {
    marginTop: theme.spacing(2),
    border: "1px solid",
    borderColor: theme.palette.lightGrey,
    borderRadius: "10px",
    overflow: "hidden",
    position: "relative",
  },
  searchSection: {
    display: "flex",
    marginTop: theme.spacing(2),
  },
  searchBox: {
    display: "flex",
    border: `1px solid ${theme.palette.lightGrey}`,
    borderRadius: "10px",
    flex: 1,
  },
  actionBox: {
    flex: 1,
    display: "flex",
    justifyContent: "space-around",
    backgroundColor: theme.palette.lightGrey,
    alignItems: "center",
    height: "100%",
  },
  actionBoxAgent: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    backgroundColor: theme.palette.lightGrey,
    alignItems: "center",
    height: "100%",
  },
  border: {
    border: `0.5px solid ${theme.palette.mediumGrey}`,
    margin: "0 10px 0 10px",
    height: "70%",
  },
  icon: {
    width: "25px",
    height: "25px",
    cursor: "pointer",
    // margin: theme.spacing(0, 1, 0, 10)
  },
  backdrop: {
    zIndex: 1,
    background: "#7574747a",
    width: "100%",
    height: "100%",
    position: "absolute",
  },
  clearFilterText: {
    textAlign: "right",
    marginTop: theme.spacing(0.5),
    fontWeight: "bold",
    cursor: "pointer",
  },
  excelButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  excelButton: {
    background: "transparent",
    border: "none",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1.5),
    "& span": {
      color: "black",
      fontSize: "1.4em",
      marginRight: theme.spacing(1),
    },
  },
  excelIcon: {
    width: "30px",
    height: "30px",
  },
  refreshIcon: {
    width: "15px",
    height: "15px",
  },
  refreshBox: {
    backgroundColor: colors.ORANGE,
    borderRadius: "9px",
    border: "1px solid",
    borderColor: colors.ORANGE,
    padding: theme.spacing(1),
    cursor: "pointer",
    display: 'flex',
    alignItems: "center",
    marginLeft: "8px"
  },
  refreshText: {
    fontSize: "1em",
    marginRight: "7px",
    color: colors.WHITE,
    border: "none",
    background: "none",
    cursor: "pointer",
  }
}));

export default useStyles;
